import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  Highlight,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";
import React, { useContext, useEffect, useRef, useState } from "react";
// import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosSearch,
  PosSwitchButton,
  PosTab,
  PosTextArea,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import {
  calculateDiscountedPrice,
  reverseCalculateDiscountedPrice,
  twofix,
} from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme } from "../../../theme/index";
import {
  createProduct,
  getLocationsCategoriesAndTaxs,
  getVendorList,
} from "./ProductService";
const ProductCreate = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisableVendor, setButtonDisableVendor] = useState(false);
  // const submit = useSubmit();
  const { error } = PosErrorHook();
  // const loaderResponse = useLoaderData();
  // const actionResponse = useActionData();
  // const effectRun = useRef(true);
  // const actionRun = useRef(false);

  const [isMobile] = useMediaQuery("(max-width: 992px)");
  // const tabNames = ["In-Store", "Online Ordering"];
  // const [inStore, setInStore] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const tabNames = [
    "In-Store",
    "Additional Attributes",
    "Unit of Measures",
    "Online Ordering",
  ];
  //   const [onlineOrdering, setOnlineOrdering] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    Constants.DEFAULT_BACKGROUND_COLOR
  );
  const [borderColor, setBorderColor] = useState(
    Constants.DEFAULT_BORDER_COLOR
  );
  const [textColor, setTextColor] = useState(Constants.DEFAULT_TEXT_COLOR);
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [prodImage, setProdImage] = useState("");
  // const [totalCount, setTotalCount] = useState("");
  const [vendors, setVendor] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [vendorscard, setVendorsCard] = useState([]);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [selectedModifierSets, setSelectedModifierSets] = useState([]);
  const [selectedTareContainers, setSelectedTareContainers] = useState([]);
  // const [selectedVendors, setSelectedVendors] = useState([]);
  const [itemCode, setItemCode] = useState("");
  const [itemCodeError, setItemCodeError] = useState("");

  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [modifierSets, setModifierSets] = useState([]);
  const [tareContainers, setTareContainers] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = React.useState([1]);

  const [selectedValue, setSelectedValue] = useState("");
  const [vendorNext, setVendorNext] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedVendorList, setSelectedVendorList] = useState([]);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const [cashDiscountPercentage, setCashDiscountPercentage] = useState(0);
  const [unitOfMeasures, setUnitOfMeasures] = useState([]);
  const [selectedBaseUnit, setSelectedBaseUnit] = useState([]);
  const [defaultSelectedUnit, setDefaultSelectedUnit] = useState([]);
  const [selectedDefaultSellingUnit, setSelectedDefaultSellingUnit] = useState(
    []
  );
  const [
    selectedDefaultPurchasingUnit,
    setSelectedDefaultPurchasingUnit,
  ] = useState([]);
  const autoCompleteActive = useRef(true);
  const [inputValueAuto, setInputValueAuto] = useState("");
  const [open, setOpen] = useState(false);
  const [autoSelectedUnits, setAutoSelectedUnits] = useState("");
  const [options, setOptions] = useState([]);
  const inputRefs = useRef([]);
  const [prodData, setProdData] = useState([]);
  const [modifiedViewDatas, setModifiedViewDatas] = useState([]);
  const [unit, setUnit] = useState("");
  const [
    disableAdditionalAttributes,
    setDisableAdditionalAttributes,
  ] = useState(false);
  const stateRefLength = useRef(modifiedViewDatas.length);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 255) {
      setItemCode(inputValue);
      setItemCodeError("");
    } else {
      setItemCodeError(Constants.PRODUCT_VENDOR_ITEM_CODE_MAX_ERROR);
    }
  };

  const handleRadioChange = (value) => () => {
    const checkExistsItems = vendorscard.filter((item) => item.id == value.id);
    if (checkExistsItems.length == 1) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.PRODUCT_VENDOR_ALREADY_EXISTS,
      });
      setSelectedValue("");
    } else {
      setSelectedValue(value.id);
      setSelectedVendorList(value);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    if (props.createFlag) {
      // Call fetchData only when isOpen becomes true and effectRun is already true
      fetchData();
      // setInStore(true);
      setCurrentTab(0);
    }
    return () => {};
  }, [props.createFlag]);

  const fetchData = async () => {
    try {
      getLocationsCategoriesAndTaxs()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);

            const cat = response?.data?.data?.category;
            const loc = response?.data?.data?.location;
            const tax = response?.data?.data?.tax;
            const modifierSet = response?.data?.data?.modifierSet;
            const tareContainers = response?.data?.data?.tareContainers;
            const unitOfMeasures = response?.data?.data?.unitOfMeasures;
            const categoriesData = cat.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesData);

            const locationsData = loc.map((loc) => ({
              label: loc.name,
              value: { name: loc.name, id: loc.id },
            }));
            setLocations(locationsData);
            formik.setFieldValue(
              "selectedLocations",
              locationsData?.map((option) => option.value)
            );
            // setSelectedLocations(locationsData.map(option => option.value));
            // setSelectedLocations(locationsData);

            const taxesData = tax.map((tax) => ({
              label: tax.name + " : " + tax.percentage,
              value: { name: tax.name, id: tax.id },
            }));
            setTaxes(taxesData);

            const modifierSetData = modifierSet.map((modifierSet) => ({
              label: modifierSet.name,
              value: { name: modifierSet.name, id: modifierSet.id },
            }));
            setModifierSets(modifierSetData);

            const tareContainerData = tareContainers.map((tareContainers) => ({
              label: tareContainers.name,
              value: { name: tareContainers.name, id: tareContainers.id },
            }));
            setTareContainers(tareContainerData);

            const unitOfMeasuresData = unitOfMeasures?.map((uom) => ({
              label: uom.name,
              value: { name: uom.name, id: uom.id },
            }));
            setUnitOfMeasures(unitOfMeasuresData);

            let Optionsset = [];
            if (
              null != response?.data?.data?.defaultUOM &&
              "" != response?.data?.data?.defaultUOM
            ) {
              const matchingBaseUnitOfMeasure = unitOfMeasures?.find(
                (uom) => uom.id == response?.data?.data?.defaultUOM?.id
              );
              setSelectedBaseUnit(matchingBaseUnitOfMeasure);
              Optionsset.push({
                label: matchingBaseUnitOfMeasure.name,
                value: {
                  name: matchingBaseUnitOfMeasure.name,
                  id: matchingBaseUnitOfMeasure.id,
                },
              });
              formik.setFieldValue(
                "selectedBaseUnit",
                matchingBaseUnitOfMeasure?.name
              );

              setDefaultSelectedUnit(Optionsset);

              setSelectedDefaultSellingUnit(matchingBaseUnitOfMeasure);
              formik.setFieldValue(
                "selectedDefaultSellingUnit",
                matchingBaseUnitOfMeasure?.name
              );

              setSelectedDefaultPurchasingUnit(matchingBaseUnitOfMeasure);
              formik.setFieldValue(
                "selectedDefaultPurchasingUnit",
                matchingBaseUnitOfMeasure?.name
              );
            }

            setCashDiscountPercentage(
              response?.data?.data?.cash_discount_percentage
            );
            props.onOpen();
          } else {
            // let actionData = response?.data;
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // let actionData = err?.response?.data;
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const columnUnitsViewNames = [
    {
      columnNames: "Related Units",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Base Qty",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Pricing($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Diff(%)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "UPC",
      sortName: "",
      width: "",
    },
    {
      columnNames: "In Stock",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
  // useEffect(() => {
  // if (effectRun.current === true) {
  //   if (
  //     undefined != loaderResponse &&
  //     null != loaderResponse &&
  //     {} != loaderResponse
  //   ) {
  //     if (
  //       undefined != loaderResponse?.error &&
  //       null != loaderResponse?.error &&
  //       loaderResponse?.error
  //     ) {
  //       let actionData = loaderResponse?.response?.data;
  //       error({ actionData });
  //       myContext.handleLoading(false);
  //     } else if (
  //       null !== loaderResponse.data[Constants.FLAGE] &&
  //       true === loaderResponse.data[Constants.FLAGE]
  //     ) {
  //       myContext.handleLoading(false);
  //       const cat = loaderResponse?.data?.data?.category;
  //       const loc = loaderResponse?.data?.data?.location;
  //       const tax = loaderResponse?.data?.data?.tax;
  //       const modifierSet = loaderResponse?.data?.data?.modifierSet;
  //       const tareContainers = loaderResponse?.data?.data?.tareContainers;
  //       const categoriesData = cat.map((cat) => ({
  //         label: cat.name,
  //         value: { name: cat.name, id: cat.id },
  //       }));
  //       setCategories(categoriesData);

  //       const locationsData = loc.map((loc) => ({
  //         label: loc.name,
  //         value: { name: loc.name, id: loc.id },
  //       }));
  //       setLocations(locationsData);
  //       formik.setFieldValue(
  //         "selectedLocations",
  //         locationsData?.map((option) => option.value)
  //       );
  //       // setSelectedLocations(locationsData.map(option => option.value));
  //       // setSelectedLocations(locationsData);

  //       const taxesData = tax.map((tax) => ({
  //         label: tax.name + " : " + tax.percentage,
  //         value: { name: tax.name, id: tax.id },
  //       }));
  //       setTaxes(taxesData);

  //       const modifierSetData = modifierSet.map((modifierSet) => ({
  //         label: modifierSet.name,
  //         value: { name: modifierSet.name, id: modifierSet.id },
  //       }));
  //       setModifierSets(modifierSetData);

  //       const tareContainerData = tareContainers.map((tareContainers) => ({
  //         label: tareContainers.name,
  //         value: { name: tareContainers.name, id: tareContainers.id },
  //       }));
  //       setTareContainers(tareContainerData);
  //     }
  //     // else {
  //     //   let actionData = loaderResponse;
  //     //   error({ actionData });
  //     // }
  //   }
  //   return () => {
  //     effectRun.current = false;
  //   };
  // }

  // if (actionRun.current === true) {
  // if (
  //   undefined != actionResponse &&
  //   null != actionResponse &&
  //   {} != actionResponse
  // ) {
  //   setButtonDisable(false);
  //   if (
  //     undefined != actionResponse?.error &&
  //     null != actionResponse?.error &&
  //     actionResponse?.error
  //   ) {
  //     let actionData = actionResponse?.response?.data;
  //     error({ actionData });
  //     myContext.handleLoading(false);
  //   } else {
  //     props.navigate(Constants.PRODUCT_LIST_PATH);
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_SUCESS,
  //       alertTitle: "Product Created",
  //       alertDescription: actionResponse.data.message,
  //     });
  //     // formik.resetForm();
  //     myContext.handleLoading(false);
  //   }
  // else {
  //   let actionData = actionResponse;
  //   action({ actionData });
  // }
  // }
  // return () => {
  //   actionRun.current = false;
  // };
  // }
  // }, [loaderResponse, actionResponse]);

  // useEffect(() => {}, [vendorscard]);

  const handleTabChange = (index) => {
    // if (index == 0) {
    //   setInStore(true);
    //   //   setLoading(true);
    //   //   fetchData();
    // } else {
    //   setInStore(false);
    //   //   setLoading(true);
    //   //   fetchDeActiveTaxData();
    // }
    setCurrentTab(index);
  };

  const handleChangeBackgroundColor = (bgColor) => {
    bgColor = bgColor.toUpperCase();
    setBackgroundColor(bgColor);
  };
  const handleChangeBorderColor = (borderColor) => {
    borderColor = borderColor.toUpperCase();
    setBorderColor(borderColor);
  };
  const handleChangeTextColor = (textColor) => {
    textColor = textColor.toUpperCase();
    setTextColor(textColor);
  };

  const handleCallback = (base64Details, fileDetails) => {
    if (
      null != base64Details &&
      "" != base64Details &&
      null != fileDetails &&
      "" != fileDetails
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: fileDetails["name"],
          filetype: fileDetails["type"],
          filesize: fileDetails["size"],
          base64: base64Details.toDataURL().split(",")[1],
        };
      });
      setBase64OfUploadedImg(base64Details.toDataURL());
      setImageUploadedFlag(1);
    } else {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    }
  };

  const handleImage = (img) => {
    setProdImage(img);
    // setOldImageBase64("")
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      sku: "",
      barcode: "",
      selectedLocations: selectedLocations,
      in_stock_qty: "",
      sale_price: "",
      cash_price: "",
      online_ordering_price: "",
      cost: "",
      additional_cost: "",
      no_of_eaches: "",
      custom_per: "",
      threshold: "",
      // modifierset: "",
      selectedModifierSets: selectedModifierSets,
      selectedTaxes: selectedTaxes,
      // tare_containers: "",
      selectedTareContainers: selectedTareContainers,
      reward_points: "",
      selectedCategories: selectedCategories,
      // selectedBaseUnit: "",
      // selectedDefaultSellingUnit: "",
      // selectedDefaultPurchasingUnit: "",
      selectedBaseUnit: selectedBaseUnit?.name,
      selectedDefaultSellingUnit: selectedDefaultSellingUnit?.name,
      selectedDefaultPurchasingUnit: selectedDefaultPurchasingUnit?.name,
      sold_by_weight: false,
      exclude_ebt: false,
      prompt_qty: false,
      is_variable_price: false,
      online_is_featured_product: false,
      is_exclude_kds: false,
      age_verification: false,
      min_age_verification: "",
      background_color: "",
      border_color: "",
      text_color: "",
      selectedVendors: vendorscard,
      item_code: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.PRODUCT_NAME_REQUIRED)
        .max(191, Constants.PRODUCT_NAME_LENGTH_GREATE_THEN_191),
      description: Yup.string()
        .trim()
        .max(5000, Constants.PRODUCT_DESCRIPTION_LENGTH_GREATE_THEN_5000),
      sku: Yup.string().max(191, Constants.PRODUCT_SKU_LENGTH_GREATE_THEN_191),
      barcode: Yup.string()
        .required(Constants.PRODUCT_BARCODE_REQUIRED)
        .max(191, Constants.PRODUCT_BARCODE_LENGTH_GREATE_THEN_191),
      // .test({
      //   name: 'barcode',
      //   message: 'Barcode is already used in UPC barcode',
      //   test: (value) => {
      //       if (modifiedViewDatas?.length > 0) {
      //       // Check if every item in modifiedViewDatas has the same UPC as the input value
      //       const isUPCConsistent = modifiedViewDatas.some(item => item.upc && item.upc !== '' && item.upc == value);
      //       // If the UPCs are consistent, set the error
      //       if (isUPCConsistent) {
      //         return false;
      //       } else {
      //         return true;
      //       }
      //     }else{
      //       return true;
      //     }
      //   }
      // }),
      selectedLocations: Yup.array().min(
        1,
        Constants.PRODUCT_LOCATION_REQUIRED
      ),
      // .required(Constants.PRODUCT_LOCATION_REQUIRED),
      selectedCategories: Yup.array().min(
        1,
        Constants.PRODUCT_CATEGORY_REQUIRED
      ),
      // .required(Constants.PRODUCT_CATEGORY_REQUIRED),
      selectedBaseUnit: Yup.string().required(
        Constants.PRODUCT_BASE_UNIT_REQUIRED
      ),
      selectedDefaultSellingUnit: Yup.string().required(
        Constants.PRODUCT_SELLING_UNIT_REQUIRED
      ),
      selectedDefaultPurchasingUnit: Yup.string().required(
        Constants.PRODUCT_PURCHASING_UNIT_REQUIRED
      ),
      selectedTaxes: Yup.array(),
      selectedModifierSets: Yup.array(),
      selectedTareContainers: Yup.array(),
      selectedVendors: Yup.array(),
      in_stock_qty: Yup.number()
        .typeError(Constants.PRODUCT_IN_STOCK_QTY_NOT_VALID_ERROR)
        // .required(Constants.PRODUCT_IN_STOCK_QTY_REQUIRED)
        .min(-999999.99, Constants.PRODUCT_IN_STOCK_QTY_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_IN_STOCK_QTY_MAX_ERROR),
      sale_price: Yup.number()
        .typeError(Constants.PRODUCT_RETAIL_PRICE_NOT_VALID_ERROR)
        .required(Constants.PRODUCT_RETAIL_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_RETAIL_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_RETAIL_PRICE_MAX_ERROR),
      cash_price: Yup.number()
        .typeError(Constants.PRODUCT_CASH_PRICE_NOT_VALID_ERROR)
        // .required(Constants.PRODUCT_CASH_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_CASH_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_CASH_PRICE_MAX_ERROR),
      online_ordering_price: Yup.number()
        .typeError(Constants.PRODUCT_ONLINE_ORDERING_PRICE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_ONLINE_ORDERING_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_ONLINE_ORDERING_PRICE_MAX_ERROR),
      cost: Yup.number()
        .typeError(Constants.PRODUCT_BASE_UNIT_COST_PRICE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_BASE_UNIT_COST_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_BASE_UNIT_COST_PRICE_MAX_ERROR),
      additional_cost: Yup.number()
        .typeError(
          Constants.PRODUCT_ADDITIONAL_COST_PER_CASE_PRICE_NOT_VALID_ERROR
        )
        .min(0.01, Constants.PRODUCT_ADDITIONAL_COST_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_ADDITIONAL_COST_PRICE_MAX_ERROR),
      no_of_eaches: Yup.number()
        .typeError(Constants.PRODUCT_NO_OF_SELLABLE_UNITS_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_NO_OF_SELLABLE_UNITS_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_NO_OF_SELLABLE_UNITS_MAX_ERROR),
      custom_per: Yup.number()
        .typeError(Constants.PRODUCT_CUSTOM_MARGIN_NOT_VALID_ERROR)
        .min(1, Constants.PRODUCT_CUSTOM_MARGIN_MIN_ERROR)
        .max(100, Constants.PRODUCT_CUSTOM_MARGIN_MAX_ERROR),
      threshold: Yup.number()
        .typeError(Constants.PRODUCT_THRESHOLD_NOT_VALID_ERROR)
        .min(0.0, Constants.PRODUCT_THRESHOLD_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_THRESHOLD_MAX_ERROR),
      reward_points: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR)
        .test("max", Constants.PRODUCT_REWARD_POINTS_MAX_ERROR, function(
          value
        ) {
          if (!value || value.trim() === "") return true; // Skip validation if value is empty
          const numericValue = parseInt(value, 10);
          return numericValue <= 2147483647;
        }),
      min_age_verification: Yup.number()
        .typeError(Constants.PRODUCT_MIN_AGE_VERIFICATION_ERROR)
        .when("age_verification", {
          is: true,
          then: (schema) =>
            schema
              .required(Constants.PRODUCT_MIN_AGE_VERIFICATION_REQUIRED)
              .integer(Constants.PRODUCT_MIN_AGE_VERIFICATION_ERROR)
              .min(1, Constants.PRODUCT_MIN_AGE_VERIFICATION_MIN_ERROR)
              .max(100, Constants.PRODUCT_MIN_AGE_VERIFICATION_MAX_ERROR),
          otherwise: (schema) => schema.optional(),
        }),
      item_code: Yup.string()
        .trim()
        .max(255, Constants.PRODUCT_VENDOR_ITEM_CODE_MAX_ERROR),
    }),
    onSubmit: async (values) => {
      // actionRun.current = true;
      var unitOfMeasuresArray = [];
      var flag = false;

      if (modifiedViewDatas?.length > 0) {
        var removeAdditionalField = modifiedViewDatas.filter((pr) => {
          return pr.id !== undefined && pr.id !== "" && pr.id !== null;
        });
        // Initialize an empty array to store the results
        // Loop through the array and extract the required fields
        var upcSet = new Set(); // Set to track unique UPCs
        removeAdditionalField.forEach((item) => {
          if (null != item && "" != item) {
            if (upcSet.has(item.upc)) {
              flag = true;
              return addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.PRODUCT_MODULE,
                alertDescription: `Duplicate UPC found: ${item.upc}`,
              });
            } else {
              if (
                null != item?.baseQtyErrors &&
                "" != item?.baseQtyErrors &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.baseQtyErrors}`,
                });
              } else if (
                null != item?.base_qty &&
                "" != item?.base_qty &&
                item.base_qty <= 0 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_BASE_QTY_MIN_VALUE,
                });
              } else if (
                null != item?.base_qty &&
                "" != item?.base_qty &&
                item.base_qty > 999999.99 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_BASE_QTY_MAX_VALUES,
                });
              } else if (
                null != item?.pricingErrors &&
                "" != item?.pricingErrors &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.pricingErrors}`,
                });
              } else if (
                null != item?.pricing &&
                "" != item?.pricing &&
                item.pricing <= 0 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_PRICING_MIN_VALUE,
                });
              } else if (
                null != item?.pricing &&
                "" != item?.pricing &&
                item.pricing > 999999.99 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_PRICING_MAX_VALUES,
                });
              } else if (
                null != item?.upcErrors &&
                "" != item?.upcErrors &&
                flag == false &&
                item?.upcErrors ==
                  Constants.PRODUCT_UPC_BARCODE_LENGTH_GREATE_THEN_191
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.upcErrors}`,
                });
              } else {
                unitOfMeasuresArray.push({
                  id: item.id,
                  label: item.label,
                  base_qty: item.base_qty,
                  pricing: item.pricing,
                  upc: item.upc,
                });
                if (null != item.upc && "" != item.upc) {
                  upcSet.add(item.upc); // Add UPC to the set after pushing
                }
              }
            }
          }
        });
      }

      const isDuplicate = unitOfMeasuresArray?.some(
        (item) => item?.upc === values?.barcode
      );

      if (isDuplicate && flag == false) {
        flag = true;
        return addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription: `Primary Barcode: ${values.barcode} is located in the UPC.`,
        });
      }
      if (flag == false) {
        setButtonDisable(true);
        values.background_color = backgroundColor;
        values.border_color = borderColor;
        values.text_color = textColor;
        if (imageUploadedFlag == 1) {
          var imageDetails = {
            productpic: croppedImgDetails,
          };
          values.productImage = imageDetails;
        }
        let payload = {
          ...values,
          // selectedLocations: JSON.stringify(values.selectedLocations),
          // role: JSON.stringify(values.selectedCategories),
          // selectedTaxes: JSON.stringify(values.selectedTaxes),
          // selectedModifier: JSON.stringify(values.selectedModifierSets),
          // selected_tare_containers: JSON.stringify(values.selectedTareContainers),
          // vendors: JSON.stringify(values.selectedVendors),
          age_verification_selected: values.age_verification,
          selectedLocations: values.selectedLocations,
          role: values.selectedCategories,
          selectedTaxes: values.selectedTaxes,
          selectedModifier: values.selectedModifierSets,
          selected_tare_containers: values.selectedTareContainers,
          vendors: values.selectedVendors,
          product_related_uom: unitOfMeasuresArray,
        };
        // if (imageUploadedFlag == 1) {
        //     var imageDetails = {
        //         "categorypic": croppedImgDetails
        //     }
        //     values.categoryImage = JSON.stringify(imageDetails);
        // }

        setButtonDisable(true);
        myContext.handleLoading(true);

        createProduct(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: "Product Created",
                alertDescription: response.data.message,
              });
              // formik.resetForm();
              setButtonDisable(false);
              myContext.handleLoading(false);
              props.fetchData();
              handleClose();
              //list refresh
            } else {
              myContext.handleLoading(false);
              setButtonDisable(false);
              // let actionData = response?.data;
              let actionData = response;
              error({ actionData });
            }
          })
          .catch((err) => {
            setButtonDisable(false);
            myContext.handleLoading(false);
            // let actionData = err?.response?.data;
            let actionData = err;
            error({ actionData });
            // if (err.response.data.code === 999) {
            //   addToast({
            //     alertStatus: Constants.TOAST_TYPE_WARNING,
            //     alertTitle: Constants.TOAST_HEADING_WARNING,
            //     alertDescription: err.response.data.message,
            //   });
            //   props.navigate(Constants.LOGOUT_PAGE);
            // } else if (err.response.data.code === 401) {
            //   addToast({
            //     alertStatus: Constants.TOAST_TYPE_WARNING,
            //     alertTitle: Constants.TOAST_HEADING_WARNING,
            //     alertDescription: err.response.data.message,
            //   });
            //   props.navigate(Constants.UNAUTHORIZE_PAGE);
            // } else if (err.response.data.validationCheck) {
            //   addToast({
            //     alertStatus: Constants.TOAST_TYPE_WARNING,
            //     alertTitle: Constants.TOAST_HEADING_WARNING,
            //     alertDescription: err.response.data.message,
            //   });
            // } else {
            //   addToast({
            //     alertStatus: Constants.TOAST_TYPE_WARNING,
            //     alertTitle: Constants.TOAST_HEADING_WARNING,
            //     alertDescription: err.response.data.message,
            //   });
            // }
            // // addToast({
            // //   alertStatus: Constants.TOAST_TYPE_WARNING,
            // //   alertTitle: Constants.TOAST_HEADING_WARNING,
            // //   alertDescription: err.message,
            // // });
          });
        // submit(payload, {
        //   method: Constants.POST,
        //   path: Constants.PRODUCT_CREATE,
        // });
        //
      }
    },
  });
  // formik.setFieldValue('selectedCategories', [
  // ]);
  // const handlePageChange = (newPage) => {
  //   effectRun.current = true;
  //   // setPage(newPage);
  // };

  // const handleRowsPerPageChange = (newRowsPerPage) => {
  //   effectRun.current = true;
  //   // setRowsPerPage(newRowsPerPage);
  // };
  // const handleSortColumn = (column, sort) => {
  //   effectRun.current = true;
  //   // setLoading(true);
  //   // setSortColumn(column.sortName);
  //   // setSortType(sort);
  // };
  // const columnNames = [
  //   {
  //     columnNames: "ID",
  //     sortName: "id",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Name",
  //     sortName: "name",
  //     width: "",
  //   },
  // ];

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      const isUPCConsistent = modifiedViewDatas.some(
        (item) => item.id == e.value.id
      );

      if (isUPCConsistent) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "A base unit has already been selected in the Related Units.",
        });
      } else {
        if (selectedBaseUnit?.id != e.value.id) {
          if (selectedBaseUnit?.id == selectedDefaultSellingUnit?.id) {
            setSelectedDefaultSellingUnit("");
            formik.setFieldValue("selectedDefaultSellingUnit", "");
            // formik.setFieldTouched("selectedDefaultSellingUnit", true);
            // formik.setErrors({
            //   ...formik.errors,
            //   selectedDefaultSellingUnit: "required.",
            // });
          }
          if (selectedBaseUnit?.id == selectedDefaultPurchasingUnit?.id) {
            setSelectedDefaultPurchasingUnit("");
            formik.setFieldValue("selectedDefaultPurchasingUnit", "");
            // formik.setFieldTouched("selectedDefaultPurchasingUnit", true);
            // formik.setErrors({
            //   ...formik.errors,
            //   selectedDefaultPurchasingUnit: "required.",
            // });
          }
        }
        setSelectedBaseUnit(e.value);
        formik.setFieldValue("selectedBaseUnit", e.value.name);
        if (
          e.value.name !== undefined &&
          e.value.name !== null &&
          e.value.name !== ""
        ) {
          formik.setFieldTouched("selectedBaseUnit", false);
        }

        const unitOfMeasuresData = [
          {
            label: e.value.name,
            value: { name: e.value.name, id: e.value.id },
          },
        ];

        // Map and filter through modifiedViewDatas
        const updatedUnitOfMeasuresData = modifiedViewDatas
          ?.filter((uom) => uom.id !== "") // Filter out falsy or empty id items
          .map((uom) => ({
            label: uom.label,
            value: { name: uom.label, id: uom.id },
          }));

        // Merge unitOfMeasuresData with updatedUnitOfMeasuresData
        const combinedUnitOfMeasuresData = [
          ...updatedUnitOfMeasuresData, // Spread the mapped data from modifiedViewDatas
          ...unitOfMeasuresData, // Add the new data from unitOfMeasuresData
        ];

        // Update the state with the combined data
        setDefaultSelectedUnit(combinedUnitOfMeasuresData);
        if (updatedUnitOfMeasuresData?.length == 0) {
          handleOptionSelectDefaultSellingUnit(e);
          handleOptionSelectDefaultPurchasingUnit(e);
        }
      }
    }
  };
  const handleOptionSelectDefaultSellingUnit = (e) => {
    if (undefined !== e.value) {
      setSelectedDefaultSellingUnit(e.value);
      formik.setFieldValue("selectedDefaultSellingUnit", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedDefaultSellingUnit", false);
      }
    }
  };

  const handleOptionSelectDefaultPurchasingUnit = (e) => {
    if (undefined !== e.value) {
      setSelectedDefaultPurchasingUnit(e.value);
      formik.setFieldValue("selectedDefaultPurchasingUnit", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedDefaultPurchasingUnit", false);
      }
    }
  };

  const onSetUnitofMeasureSingleLine = (value) => {
    if (null != value && "" != value) {
      if (value.value?.id == selectedBaseUnit?.id) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription: `Cannot select ${value.label} as Related Unit because it's a Base Unit.`,
        });
        setAutoSelectedUnits("");
        setInputValueAuto("");
      } else {
        let obj = {
          id: value.value?.id,
          label: value.label,
          base_qty: "",
          pricing: "",
          margin: "",
          upc: "",
          in_stock: "",
          baseQtyErrors: Constants.PRODUCT_BASE_QTY_REQUIRED,
          pricingErrors: Constants.PRODUCT_PRICING_REQUIRED,
        };

        let objEmpty = {
          id: "",
          label: "",
          base_qty: "",
          pricing: "",
          margin: "",
          upc: "",
          in_stock: "",
        };

        // Check if the same id already exists in the array
        let idExists = modifiedViewDatas.some((item) => item.id === obj.id);

        if (idExists) {
          // Return an error message if the id already exists
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.PRODUCT_MODULE,
            alertDescription: `${value.label} is already selected as a Related Unit.`,
          });
          setAutoSelectedUnits("");
          setInputValueAuto("");
        } else {
          // Proceed with inserting the new object if id does not exist
          let array = [...modifiedViewDatas, obj];

          // Remove objEmpty if it already exists in the array
          array = array.filter(
            (item) => JSON.stringify(item) !== JSON.stringify(objEmpty)
          );

          // Add objEmpty at the last position
          array.push(objEmpty);
          setDisableAdditionalAttributes(true);

          setModifiedViewDatas(array);

          setDefaultSelectedUnit((prevState) => {
            const updatedUnitOfMeasuresData = array
              ?.filter((uom) => uom.id != "") // Filter out items where id is empty or falsy
              .map((uom) => ({
                label: uom.label,
                value: { name: uom.label, id: uom.id },
              }));

            // Create a copy of the previous state
            const newState = [...prevState];
            // Loop through the updatedUnitOfMeasuresData and either replace or add
            updatedUnitOfMeasuresData.forEach((newUom) => {
              const index = newState.findIndex(
                (existingUom) => existingUom.value.id == newUom.value.id
              );

              if (index > -1) {
                newState[index] = newUom;
              } else {
                newState.push(newUom);
              }
            });

            return newState;
          });
        }
      }
      // setModifiedViewDatas(unitOfMeasures.map((item) => ({
      //   // id: item.id,
      //   label: value.label,
      //   base_qty: "",
      //   pricing: "",
      //   margin: "",
      //   upc: "",
      //   in_stock:""
      // })));
    }
  };
  useEffect(() => {
    if (stateRefLength.current != modifiedViewDatas.length) {
      if (inputRefs.current[modifiedViewDatas.length - 2]) {
        inputRefs.current[modifiedViewDatas.length - 2].focus();
      }
    }
  }, [modifiedViewDatas.length]);

  const handleInputChangeUOM = (event, id, field_name) => {
    event.preventDefault();
    const { value } = event.target;
    let index = modifiedViewDatas.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...modifiedViewDatas[index] };
      // let arrayData = Object.assign([],modifiedViewDatas.slice(0,-1))
      // let index = arrayData.findIndex((x) => x.id === id);
      // if (index > -1) {
      //   let obj = { ...arrayData[index] };
      //   let arrayOfOthers = [...arrayData.slice(0,index),...arrayData.slice(index+1)]
      //   arrayOfOthers.map((unit) => {
      //     if(null != unit?.upc && "" != unit?.upc && "" != value && field_name == "upc"){
      //       if(unit.upc != value && (unit.upcErrors == "UPC is already used.") && formik.values.barcode != unit.upc){
      //         let alreadyExitsBarcode = arrayOfOthers.filter(x => x.upc == unit.upc)
      //         let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //         if(upc_error_find > -1 && alreadyExitsBarcode.length == 1) {
      //           modifiedViewDatas[upc_error_find].upcErrors=""
      //         }
      //       } else if(unit.upc == value) {
      //         let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //         if(upc_error_find > -1) {
      //           modifiedViewDatas[upc_error_find].upcErrors="UPC is already used."
      //         }
      //       }
      //     }
      //   })

      obj[field_name] = value;
      switch (field_name) {
        case "base_qty":
          const parsedBaseQty = parseFloat(value);
          obj.baseQtyErrors =
            value.length === 0
              ? Constants.PRODUCT_BASE_QTY_REQUIRED
              : parseFloat(value) <= 0.0
              ? Constants.PRODUCT_BASE_QTY_MIN_VALUE
              : isNaN(parsedBaseQty) || !/^\d*\.?\d*$/.test(value)
              ? Constants.PRODUCT_PLEASE_ENTER_A_VALID_BASE_QTY
              : parseFloat(value) > 1000000
              ? Constants.PRODUCT_BASE_QTY_MAX_VALUES
              : "";
          break;
        case "pricing":
          const parsedPricing = parseFloat(value);
          obj.pricingErrors =
            value.length === 0
              ? Constants.PRODUCT_PRICING_REQUIRED
              : parseFloat(value) <= 0.0
              ? Constants.PRODUCT_PRICING_MIN_VALUE
              : isNaN(parsedPricing) || !/^\d*\.?\d*$/.test(value)
              ? Constants.PRODUCT_PLEASE_ENTER_A_VALID_PRICING
              : parseFloat(value) > 1000000
              ? Constants.PRODUCT_PRICING_MAX_VALUES
              : "";
          break;
        case "upc":
          obj.upcErrors =
            value.length > 192
              ? Constants.PRODUCT_UPC_BARCODE_LENGTH_GREATE_THEN_191
              : // : (formik.values.barcode && formik.values.barcode === value)
                // ? "UPC is already used in primary barcode."
                // : modifiedViewDatas?.some(item =>
                //     item.upc && item.upc !== "" && item.upc === value && item.id !== id
                //   )
                // ? "UPC is already used."
                ""; // No errors if all checks pass.
          break;

        default:
          break;
      }

      const updatedArray = modifiedViewDatas.map((item, i) => {
        if (i === index) {
          // Apply margin calculation only for the updated item
          const updatedObj = { ...obj };

          if (
            (updatedObj.baseQtyErrors === undefined ||
              updatedObj.baseQtyErrors === "") &&
            (updatedObj.pricingErrors === undefined ||
              updatedObj.pricingErrors === "") &&
            formik.errors.sale_price === undefined && // Check for undefined instead of empty string
            updatedObj.base_qty > 0 &&
            updatedObj.pricing > 0 &&
            formik.values.sale_price > 0
          ) {
            const parsedBaseQty = parseFloat(updatedObj.base_qty);
            const parsedPricing = parseFloat(updatedObj.pricing);
            const parsedSalePrice = parseFloat(formik.values.sale_price);

            if (
              !isNaN(parsedBaseQty) &&
              !isNaN(parsedPricing) &&
              !isNaN(parsedSalePrice) &&
              parsedBaseQty > 0 &&
              parsedPricing > 0 &&
              parsedSalePrice > 0
            ) {
              const total =
                100 - (parsedPricing * 100) / (parsedSalePrice * parsedBaseQty);
              updatedObj.margin = parseFloat(total).toFixed(2);
            } else {
              updatedObj.margin = "N.A.";
            }
          } else {
            updatedObj.margin = "N.A.";
          }

          if (
            (updatedObj.baseQtyErrors === undefined ||
              updatedObj.baseQtyErrors === "") &&
            formik.errors.in_stock_qty === undefined && // Check for undefined instead of empty string
            updatedObj.base_qty > 0 &&
            formik.values.in_stock_qty > 0
          ) {
            // Ensure valid parsing of numbers before division
            const parsedInStockQty = parseFloat(formik.values.in_stock_qty);
            const parsedBaseQty = parseFloat(updatedObj.base_qty);

            if (
              !isNaN(parsedInStockQty) &&
              !isNaN(parsedBaseQty) &&
              parsedBaseQty > 0
            ) {
              const inStock = parsedInStockQty / parsedBaseQty;
              updatedObj.in_stock = parseFloat(inStock).toFixed(2);
            } else {
              updatedObj.in_stock = "N.A.";
            }
          } else {
            updatedObj.in_stock = "N.A.";
          }

          return updatedObj;
        }
        return item;
      });
      // let flag = 0
      // updatedArray?.map((item, i) => {
      //   if(item.upc == formik.values.barcode) {
      //     flag = 1
      //   }
      //   if(i+1 == updatedArray.length && !flag) {
      //     delete formik.errors.barcode;
      //   }
      // });

      setModifiedViewDatas(updatedArray);
    }
  };

  const deleteById = (idToDelete) => {
    // Filter the array to remove the object with the matching id
    if (selectedDefaultSellingUnit?.id == idToDelete) {
      setSelectedDefaultSellingUnit("");
      formik.setFieldValue("selectedDefaultSellingUnit", "");
    }

    if (selectedDefaultPurchasingUnit?.id == idToDelete) {
      setSelectedDefaultPurchasingUnit("");
      formik.setFieldValue("selectedDefaultPurchasingUnit", "");
    }

    const updatedArray = modifiedViewDatas.filter(
      (item) => item.id !== idToDelete
    );
    setModifiedViewDatas(updatedArray);

    if (updatedArray?.length == 1) {
      setDisableAdditionalAttributes(false);
    }

    setDefaultSelectedUnit((prevState) => {
      // Filter out the item from the dropdown state if its id matches idToDelete
      const updatedUnitOfMeasuresData = prevState.filter(
        (item) => item.value.id !== idToDelete
      );

      // You don’t need to loop through modifiedViewDatas and update dropdown data here,
      // because you’re directly removing the deleted item from dropdown state.
      return updatedUnitOfMeasuresData;
    });
  };

  const addVendors = (data) => {
    setButtonDisableVendor(true);
    setTimeout(() => {
      vendorscard.push({
        ...data,
        item_code: itemCode,
      });
      // setSelectedVendors(data);
      setVendorNext(false);
      onClose();
      setSelectedValue("");
      setSelectedVendorList([]);
      setSearchText("");
      setButtonDisableVendor(false);
    }, 1000);
  };

  const getVendor = async () => {
    let data = {
      limit: 1000000,
      page: 0 + 1,
      order: "id",
      order_type: "desc",
      filter: "",
    };
    setItemCode("");
    setItemCodeError("");
    setVendorNext(false);
    setSelectedValue("");
    setSelectedVendorList([]);
    setSearchText("");
    setButtonDisableVendor(false);
    try {
      getVendorList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            // setTotalCount(response.data.data.totalcount);
            setVendor(response.data.data.data);
            onOpen();
          } else {
            // let actionData = response?.data;
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // let actionData = err?.response?.data;
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const onNext = () => {
    if (selectedValue > 0) {
      setVendorNext(true);
      setItemCode("");
      setItemCodeError("");
    }
  };

  const onClosed = () => {
    setSelectedValue("");
    setSelectedVendorList([]);
    setVendorNext(false);
    setSearchText("");
    // setVendorsCard([]);
    onClose();
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    // fetchData();
  };

  const handleClear = () => {
    // clear input value and get all data
    setSearchText("");
  };

  const onRemove = (data) => {
    let array = [];
    let index = vendorscard.findIndex((item) => item.id === data.id);
    if (index > -1) {
      array = [...vendorscard.slice(0, index), ...vendorscard.slice(index + 1)];
    }
    formik.values.selectedVendors = array;
    setVendorsCard(array);
  };

  const handleClose = () => {
    formik.resetForm();
    setSelectedValue("");
    setSelectedVendorList([]);
    setVendorNext(false);
    setSearchText("");
    setVendorsCard([]);
    setBase64OfUploadedImg("");
    setBackgroundColor(Constants.DEFAULT_BACKGROUND_COLOR);
    setBorderColor(Constants.DEFAULT_BORDER_COLOR);
    setTextColor(Constants.DEFAULT_TEXT_COLOR);
    // setOldImageBase64("");
    setImageUploadedFlag(0);
    setProdImage("");
    setUnitOfMeasures([]);
    setUnit("");
    // formik.setFieldTouched("selectedBaseUnit", false);
    setSelectedBaseUnit([]);
    setDefaultSelectedUnit([]);
    setSelectedDefaultSellingUnit([]);
    setSelectedDefaultPurchasingUnit([]);
    setModifiedViewDatas([]);
    setDisableAdditionalAttributes(false);
    setAllTouchedField(false);
    if (props.onClose) {
      props.onClose();
      // props.current=true
    }
  };

  const formatProductName = (name) => {
    if (!name) return "PR";
    const firstLetter = name.charAt(0).toUpperCase();
    const secondLetter = name.charAt(1).toUpperCase();
    return firstLetter + secondLetter;
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const checkAndUpdateInStock = (inStockQty) => {
    // Iterate over modifiedViewDatas to check base_qty and update in_stock_qty if needed
    const updatedData = modifiedViewDatas.map((item) => {
      if (inStockQty != undefined && inStockQty != null && inStockQty != "") {
        if (item.base_qty > 0 && inStockQty > 0) {
          // Calculate new in_stock_qty based on base_qty or any other rule
          const uomInStock = parseFloat(inStockQty) / item.base_qty;
          item.in_stock = parseFloat(uomInStock).toFixed(2);
          // calculateInStock(item.base_qty, inStockQty);
        }
        return item;
      } else {
        item.in_stock = "N.A.";
        return item;
      }
    });

    // Update the state with the modified array
    setModifiedViewDatas(updatedData);
  };

  const checkAndUpdatePrice = (marginSalePrice) => {
    const updatedData = modifiedViewDatas.map((item) => {
      if (
        marginSalePrice != undefined &&
        marginSalePrice != null &&
        marginSalePrice != ""
      ) {
        if (item.base_qty > 0 && item.pricing > 0 && marginSalePrice > 0) {
          // Calculate new in_stock_qty based on base_qty or any other rule
          const total =
            100 -
            (parseFloat(item.pricing) * 100) /
              (parseFloat(marginSalePrice) * parseFloat(item.base_qty));
          item.margin = parseFloat(total).toFixed(2);
          // calculateInStock(item.base_qty, inStockQty);
        }
        return item;
      } else {
        item.margin = "N.A.";
        return item;
      }
    });

    // Update the state with the modified array
    setModifiedViewDatas(updatedData);
  };
  const formatedProductName = (name) => {
    const capitalized = capitalizeWords(name);
    return truncateText(capitalized, 30);
  };

  const formattingName = formatedProductName(formik.values.name);
  const formattedName = formatProductName(formik.values.name);
  const capitalizedProductName = capitalizeWords(formik.values.name);

  return (
    <PosDrawer
      visible={props.isOpen}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      {/* {" "} */}
      {/* <Flex bg={Colors.loginAuthBackground} p={0}> */}
      <Box padding={2}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
          pb={"0.62rem"}
        >
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            // pt={{ base: "1.5rem", md: "3.06rem" }}
            // pb={{ base: "1.5rem", md: "0" }}
            // pl={"1.88rem"}
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "1rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              breadCrumNames={["Products", "Create Product"]}
              breadCrumTitle={"Create Product"}
            />
            <Spacer />
          </Flex> */}
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                breadCrumNames={["Products", "Create Product"]}
                breadCrumTitle={"Create Product"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
              /> */}
              <PosFormButton
                isDisabled={buttonDisable ? true : false}
                buttonsubmit={"Submit"}
                SubmitButton={true}
                onClick={() => {
                  if (
                    undefined != selectedDefaultSellingUnit?.id &&
                    null != selectedDefaultSellingUnit?.id &&
                    "" != selectedDefaultSellingUnit?.id &&
                    selectedDefaultSellingUnit?.id > 0 &&
                    undefined != selectedDefaultPurchasingUnit?.id &&
                    null != selectedDefaultPurchasingUnit?.id &&
                    "" != selectedDefaultPurchasingUnit?.id &&
                    selectedDefaultPurchasingUnit?.id > 0
                  ) {
                    delete formik.errors.selectedDefaultSellingUnit;
                    delete formik.errors.selectedDefaultPurchasingUnit;
                    formik.isValid = true;
                  }
                  // submit time all validation fired and error massage display.
                  if (!formik.isValid) {
                    if (!allTouchedField) {
                      formik.handleSubmit();
                      setAllTouchedField(true);
                    }
                    addToast({
                      alertStatus: Constants.TOAST_TYPE_ERROR,
                      alertTitle: Constants.SUBMIT_TIME_FORM_NOT_VALID_TITLE,
                      alertDescription: Constants.SUBMIT_TIME_FORM_NOT_VALID,
                    });
                  } else {
                    formik.handleSubmit();
                  }
                }}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        <Flex
          gap={"0.63rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "1rem" : "1rem"}
        >
          <Tabs onChange={(index) => handleTabChange(index)}>
            <PosTab tabNames={tabNames} />
          </Tabs>
        </Flex>
        <Flex
          gap={!isMobile ? "0.63rem" : "0rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "0.875rem" : "0.875rem"}
        >
          {/* <Tabs onChange={(index) => handleTabChange(index)}>
              <PosTab tabNames={tabNames} /> */}
          {currentTab === 0 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                //   boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Basic Details
                      </Heading>
                    </Box>
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          requiredLabel={true}
                          name={"Primary Barcode (ISBN, UPC, GTIN)"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="This is the Barcode that will be associated with the specific product. The system will ring up the associated Product when this barcode is ringed up."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            // mt={{ base: "0", md: "2rem" }}
                            // ml={{ base: "0rem", md: "0.3rem" }}
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      <PosInput
                        id="barcode"
                        // placeholder={"Enter a Primary barcode"}
                        handleInputChange={formik.handleChange}
                        // handleInputChange={formik.handleChange
                        //   if(value == formik.values.barcode){
                        //   }
                        // }
                        // handleInputChange={(e) => {
                        //   formik.setFieldValue(
                        //     "barcode",
                        //     e?.target?.value
                        //   );
                        //   // Set the updated array back into state
                        //   // setModifiedViewDatas(updatedViewDatas);
                        //   modifiedViewDatas.filter((pr) => pr.id !== undefined && pr.id !== "" && pr.id !== null)
                        //   .map((item,index)=>{
                        //     if (item.upc && item.upc !== "" && item.upc !== null) {
                        //       if (item.upc !== e?.target?.value) {
                        //         item.upcErrors = '';
                        //       } else {
                        //         item.upcErrors = 'Barcode is same';
                        //       }
                        //     }
                        //   })
                        // }}
                        inputValue={formik.values.barcode}
                        handleBlur={formik.handleBlur}
                        posInput={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.barcode && formik.errors.barcode
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.barcode && formik.errors.barcode ? (
                          <span>{formik.errors.barcode}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        requiredLabel={true}
                        name={"Product Name"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="name"
                        // placeholder={"Enter a Product Name"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.name}
                        handleBlur={formik.handleBlur}
                        posInput={true}
                        inputType={"text"}
                        inputError={formik.touched.name && formik.errors.name}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.name && formik.errors.name ? (
                          <span>{formik.errors.name}</span>
                        ) : null}
                      </Text>
                    </Box>
                    {/* <Box mt={{ base: "2rem", md: "2rem" }}>
                            <PosImageCropper parentCallback={handleCallback} />
                          </Box> */}
                    <Box className="card flex justify-content-center">
                      <Flex flexDirection={"row"}>
                        <PosLable
                          requiredLabel={true}
                          name={"Product Category"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="New Category can be made from Product-Categories-create. These categories help in classifying and navigating products easily."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      {/* <MultiSelect
                          value={formik?.values?.selectedCategories}
                          onChange={(e) =>
                            formik.setFieldValue("selectedCategories", e.value)
                          }
                          // appendTo={"self"}
                          options={categories}
                          optionLabel="label"
                          filter
                          placeholder="Select Categories"
                          maxSelectedLabels={2}
                          // border: "0.125rem solid",borderColor: "#F26464", boxShadow: "0px 0px 0px 3px " + "#FEE6E6"
                          style={{ width: "100%" }}
                          className="w-full md:w-20rem"
                        />
                        <Text color={Colors.errorColor}>
                          {formik.errors.selectedCategories &&
                          formik.errors.selectedCategories ? (
                            <span>{formik.errors.selectedCategories}</span>
                          ) : null}
                        </Text> */}
                      <PosDropDown
                        options={categories}
                        value={formik?.values?.selectedCategories}
                        onChange={(e) =>
                          formik.setFieldValue("selectedCategories", e.value)
                        }
                        onBlur={(e) => {
                          formik.setFieldTouched("selectedCategories", e.value);
                          formik.handleBlur(e);
                        }}
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="--Select Categories--"
                        lableAvailable={true}
                        width={"100%"}
                        height={"2.5rem"}
                        className="w-full md:w-20rem"
                        // display={"chip"}
                        maxSelectedLabels={2}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.selectedCategories &&
                        formik.errors.selectedCategories ? (
                          <span>{formik.errors.selectedCategories}</span>
                        ) : null}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={!isMobile ? "0rem" : 3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Inventory Management
                      </Heading>
                    </Box>

                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={"Locations for this Product"}
                          marginTop={"1rem"}
                        />
                        <PosDropDown
                          options={locations}
                          value={formik?.values?.selectedLocations}
                          onChange={(e) =>
                            formik.setFieldValue("selectedLocations", e.value)
                          }
                          onBlur={(e) => {
                            formik.setFieldTouched(
                              "selectedLocations",
                              e.value
                            );
                            formik.handleBlur(e);
                          }}
                          multiSelect={true}
                          optionLabel="label"
                          placeholder="--Select Locations--"
                          lableAvailable={true}
                          width={"100%"}
                          height={"2.5rem"}
                          className="w-full md:w-20rem"
                          display={"chip"}
                          maxSelectedLabels={2}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.selectedLocations &&
                          formik.errors.selectedLocations ? (
                            <span>{formik.errors.selectedLocations}</span>
                          ) : null}
                        </Text>
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={"Base Unit"}
                          marginTop={"1rem"}
                        />
                        <PosDropDown
                          id="selectedBaseUnit"
                          options={unitOfMeasures}
                          value={selectedBaseUnit}
                          onChange={handleOptionSelect}
                          // onChange={(e) => {
                          //   if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                          //     handleOptionSelect(e);
                          //   }
                          // }}
                          onBlur={(e) => {
                            formik.setFieldTouched("selectedBaseUnit");
                            formik.handleBlur(e);
                          }}
                          // onBlur={() => formik.handleBlur("selectedBaseUnit")}
                          lableAvailable={true}
                          optionLabel="label"
                          placeholder="Select Base Unit"
                          width={"100%"}
                          className="w-full md:w-20rem"
                        />
                        {/* <PosDropDown
                            id="selectedBaseUnit" 
                            options={unitOfMeasures}
                            value={formik?.values?.selectedBaseUnit}
                            onChange={(e) =>
                              formik.setFieldValue("selectedBaseUnit", e.value)
                            }
                            onBlur={(e) => {
                              formik.setFieldTouched(
                                "selectedBaseUnit",
                                e.value
                              );
                              formik.handleBlur(e);
                            }}
                            // multiSelect={true}
                            optionLabel="label"
                            placeholder="Select Base Unit"
                            lableAvailable={true}
                            width={"100%"}
                            // height={"2.5rem"}
                            className="w-full md:w-20rem"
                            // display={"chip"}
                            // maxSelectedLabels={2}
                          /> */}
                        <Text color={Colors.errorColor}>
                          {formik.touched.selectedBaseUnit &&
                          formik.errors.selectedBaseUnit ? (
                            <span>{formik.errors.selectedBaseUnit}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"In Stock Qty"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="in_stock_qty"
                          // placeholder={"Enter an In Stock Qty"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.in_stock_qty || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            // Apply formatting to in_stock_qty
                            const formattedStock = twofix(
                              formik.values.in_stock_qty
                            );
                            formik.setFieldValue(
                              "in_stock_qty",
                              formattedStock
                            );
                            // Check modifiedViewDatas and perform calculation if base_qty > 0
                            checkAndUpdateInStock(formattedStock);
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.in_stock_qty &&
                            formik.errors.in_stock_qty
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.in_stock_qty &&
                          formik.errors.in_stock_qty ? (
                            <span>{formik.errors.in_stock_qty}</span>
                          ) : null}
                        </Text>
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Flex flexDirection={"row"}>
                          <PosLable
                            label={true}
                            name={"Threshold"}
                            marginTop={"1rem"}
                          />
                          <Tippy
                            content="Mention the Minimum product level to which when dropped below, you will be notified of it and can also be used to make an automated Purchase order."
                            interactive={true}
                            maxWidth={390}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              mt={"1rem"}
                              ml={"0.3rem"}
                            />
                          </Tippy>
                        </Flex>
                        <PosInput
                          id="threshold"
                          // placeholder={"Enter a Threshold"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.threshold || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "threshold",
                              twofix(formik.values.threshold)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.threshold && formik.errors.threshold
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.threshold &&
                          formik.errors.threshold ? (
                            <span>{formik.errors.threshold}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Box>
                    <Box>
                      <PosLable label={true} name={"SKU"} marginTop={"1rem"} />
                      <PosInput
                        id="sku"
                        // placeholder={"Enter a SKU"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.sku}
                        handleBlur={formik.handleBlur}
                        posInput={true}
                        inputType={"text"}
                        inputError={formik.touched.sku && formik.errors.sku}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.sku && formik.errors.sku ? (
                          <span>{formik.errors.sku}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                      justify="space-between"
                      pt={"1rem"}
                      pb={"0.5rem"}
                      // pb={16}
                    >
                      <Flex flexDirection={"row"}>
                        <Heading
                          as={"h3"}
                          color={Colors.moduleHeading}
                          fontSize={"1.13rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.02rem"}
                        >
                          Vendors
                        </Heading>
                        <Tippy
                          content="This lets you associate the product with a vendor, which helps when making a purchase order and tracking the product history."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            // mt={"2rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      <Spacer />

                      <Stack direction={"row"} alignSelf={"flex-end"}>
                        <PosFormButton
                          buttonsubmit={"Add Vendor"}
                          SubmitButton={true}
                          onClick={getVendor}
                        />
                      </Stack>
                    </Flex>

                    <Box
                      style={{
                        maxHeight: "18.75rem",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {vendorscard.map((item, index) => {
                        return (
                          <Card
                            position="relative"
                            bg={"#F4F4F4"}
                            borderRadius={5}
                            marginTop={"0.5rem"}
                            key={index}
                            // px={"1rem"}
                            // py={"1.31rem"}
                          >
                            <Flex align="center">
                              <CardBody>
                                <Avatar
                                  sx={{
                                    bgcolor: "rgba(88, 129, 254, 0.1)",
                                    color: "rgba(1, 0, 72, 0.80)",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {item.name.substring(0, 2).toUpperCase()}
                                </Avatar>
                                <Text pt={4} fontSize={"md"} fontWeight={500}>
                                  {item.name}{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                      color: "rgba(1, 0, 72, 0.60)",
                                    }}
                                  >
                                    {item.state ? item.state + "," : null}{" "}
                                    {item.country ? item.country : null}{" "}
                                  </span>
                                </Text>
                                {item?.email?.length > 0 ? (
                                  <Text
                                    color={"#5881FE"}
                                    fontSize={"xs"}
                                    fontWeight={300}
                                  >
                                    {item.email}
                                  </Text>
                                ) : null}

                                {item?.item_code?.length > 0 ? (
                                  <Text
                                    fontSize={"xs"}
                                    fontWeight={400}
                                    color={"rgba(1, 0, 72, 0.80)"}
                                    pt={4}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    Item code: {item.item_code}
                                  </Text>
                                ) : null}
                              </CardBody>
                              <Box
                                position="absolute"
                                top="0.5rem"
                                right="1rem"
                              >
                                <CloseIcon
                                  color={Colors.posCancleButtonRed}
                                  onClick={() => onRemove(item)}
                                />
                              </Box>
                              {/* <Box
                                      position="absolute"
                                      top="0"
                                      right="0"
                                      // mt="2"
                                      // mr="2"
                                    >
                                      <IconButton
                                        icon={<CloseIcon />}
                                        // onClick={onRemove}
                                        onClick={() => onRemove(item)}
                                        colorScheme="red"
                                        variant="ghost"
                                        aria-label="Remove"
                                      />
                                    </Box> */}
                            </Flex>
                          </Card>
                        );
                      })}
                    </Box>
                  </VStack>
                  <Modal
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"lg"}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader
                        color={"#010048"}
                        fontSize={"1.5rem"}
                        fontWeight={600}
                      >
                        Add Vendor
                      </ModalHeader>
                      {/* <ModalCloseButton /> */}
                      <Divider />
                      {!vendorNext ? (
                        <>
                          <ModalBody pt={"1.5rem"}>
                            <PosSearch
                              value={searchText}
                              // onChange={(e) => setSearchText(e.target.value)}
                              onChange={(e) => {
                                let value =
                                  searchText.length > 0
                                    ? e.target.value
                                    : e.target.value.trim();
                                setSearchText(value);
                              }}
                              onSearch={handleSearchList}
                              handleClear={handleClear}
                            />
                            <Spacer />
                            <List
                              style={{
                                maxHeight: "18.75rem",
                                overflow: "auto",
                                scrollbarWidth: "thin",
                              }}
                            >
                              {filteredVendors?.map((value) => {
                                const labelId = `${value.id}`;

                                return (
                                  <ListItem
                                    key={value.id}
                                    disablePadding
                                    onClick={handleRadioChange(value)}
                                  >
                                    <ListItemButton>
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{
                                            bgcolor: "#EFEFEF",
                                            color: "rgba(1, 0, 72, 0.80)",
                                            fontSize: "15px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {value.name
                                            .substring(0, 2)
                                            .toUpperCase()}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        id={labelId}
                                        primary={
                                          <Box display={"flex"}>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "#010048",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {value.name}
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "rgba(1, 0, 72, 0.60)",
                                                fontWeight: 300,
                                              }}
                                              ml="0.3rem"
                                            >
                                              {null != value.state &&
                                              "" != value.state
                                                ? value.state
                                                : ""}
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "rgba(1, 0, 72, 0.60)",
                                                fontWeight: 300,
                                              }}
                                            >
                                              {null != value.country &&
                                              "" != value.country
                                                ? ", " + value.country
                                                : ""}
                                            </Typography>
                                          </Box>
                                        }
                                        secondary={
                                          <Typography
                                            variant="body2"
                                            style={{ color: "#5881FE" }}
                                          >
                                            {value.email}
                                          </Typography>
                                        }
                                      />
                                      <Radio
                                        checked={selectedValue === value.id}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </ModalBody>{" "}
                          <ModalFooter>
                            <ButtonGroup gap="2" alignSelf={"flex-end"}>
                              <PosFormButton
                                onClick={onClose}
                                buttonText={"Cancel"}
                                CancelButton={true}
                              />
                              <PosFormButton
                                isDisabled={
                                  selectedValue?.length == 0 ? true : false
                                }
                                buttonsubmit={"Next"}
                                SubmitButton={true}
                                onClick={onNext}
                              />
                            </ButtonGroup>
                          </ModalFooter>
                        </>
                      ) : (
                        <>
                          <ModalBody>
                            <Box>
                              <PosLable
                                label={true}
                                name={"Item Code"}
                                marginTop={"1rem"}
                              />
                              <PosInput
                                id="item_code"
                                placeholder={"Enter an Item Code"}
                                handleInputChange={handleChange}
                                inputValue={itemCode}
                                posInput={true}
                                inputType={"text"}
                                inputError={
                                  itemCodeError?.length > 0
                                    ? itemCodeError
                                    : null
                                }
                              />
                              <Text color={Colors.errorColor}>
                                {itemCodeError?.length > 0 ? (
                                  <span>{itemCodeError}</span>
                                ) : null}
                              </Text>
                            </Box>
                          </ModalBody>
                          <ModalFooter>
                            <PosFormButton
                              onClick={onClosed}
                              buttonText={"Cancel"}
                              CancelButton={true}
                            />
                            <PosFormButton
                              isDisabled={
                                buttonDisableVendor || itemCodeError?.length > 0
                                  ? true
                                  : false
                              }
                              buttonsubmit={"Add Vendor"}
                              SubmitButton={true}
                              onClick={() => addVendors(selectedVendorList)}
                            />
                          </ModalFooter>
                        </>
                      )}
                      {/* {totalCount > 0 ? ( */}

                      {/* <PosTable
                        columnNames={columnNames}
                        rawdata={vendors}
                        totalcount={totalCount}
                        spliteButton={true}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        handleSortColumn={handleSortColumn}
                        sortColumnName={"id"}
                        order={"asc"}
                      /> */}
                      {/* ) : (
                        <PosNoDataFound title={"no data found"} />
                      )} */}
                    </ModalContent>
                  </Modal>
                </Box>

                {/* {selectedBaseUnit &&selectedBaseUnit?.id > 0? */}

                {/* : null} */}
              </Box>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                //   boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Pricing
                      </Heading>
                    </Box>
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "100%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Base Unit Cost(Current)"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="cost"
                          // placeholder={"Enter a Cost Per Case"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.cost || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "cost",
                              twofix(formik.values.cost)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={formik.touched.cost && formik.errors.cost}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.cost && formik.errors.cost ? (
                            <span>{formik.errors.cost}</span>
                          ) : null}
                        </Text>
                      </Box>
                      {/* <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Additional Cost"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="additional_cost"
                          // placeholder={"Enter an Additional Cost"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.additional_cost || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "additional_cost",
                              twofix(formik.values.additional_cost)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.additional_cost &&
                            formik.errors.additional_cost
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.additional_cost &&
                          formik.errors.additional_cost ? (
                            <span>{formik.errors.additional_cost}</span>
                          ) : null}
                        </Text>
                      </Box> */}
                    </Box>
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Sellable Unit in a Case"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="no_of_eaches"
                        // placeholder={"Enter a Sellable Unit in a Case"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.no_of_eaches || ""}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "no_of_eaches",
                            twofix(formik.values.no_of_eaches)
                          );
                        }}
                        precision={2}
                        posNumberInput={true}
                        // inputType={"text"}
                        inputError={
                          formik.touched.no_of_eaches &&
                          formik.errors.no_of_eaches
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.no_of_eaches &&
                        formik.errors.no_of_eaches ? (
                          <span>{formik.errors.no_of_eaches}</span>
                        ) : null}
                      </Text>
                    </Box> */}
                    {/* {!formik.errors.cost &&
                    !formik.errors.no_of_eaches &&
                    !formik.errors.additional_cost &&
                    null != formik.values.cost &&
                    "" != formik.values.cost &&
                    null != formik.values.no_of_eaches &&
                    "" != formik.values.no_of_eaches ? (
                      <>
                        <Box>
                          <Text
                            color={"#010048"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            Price of individual Unit:{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                                textDecorationLine: "underline",
                              }}
                            >
                              $
                              {(
                                (parseFloat(formik.values.cost) +
                                  parseFloat(
                                    formik.values.additional_cost || 0.0
                                  )) /
                                parseFloat(formik.values.no_of_eaches)
                              ).toFixed(2)}
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.80)"}
                            fontWeight={300}
                            fontSize={"0.94rem"}
                            mt={{ base: "0.88rem", md: "0.88rem" }}
                            mb={{ base: "0.88rem", md: "0.88rem" }}
                          >
                            *Selling Price Recommendations at various margins*
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            20%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 20 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            25%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 25 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            30%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 30 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            35%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 35 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                        </Box>
                        <Box>
                          <PosLable
                            label={true}
                            name={"Custom Margin"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="custom_per"
                            // placeholder={"Enter a Custom Margin"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.custom_per || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "custom_per",
                                twofix(formik.values.custom_per)
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.custom_per &&
                              formik.errors.custom_per
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.custom_per &&
                            formik.errors.custom_per ? (
                              <span>{formik.errors.custom_per}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {!formik.errors.custom_per &&
                        null != formik.values.custom_per &&
                        "" != formik.values.custom_per ? (
                          <Box>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={300}
                              fontSize={"0.94rem"}
                            >
                              *Suggested Selling Price at{" "}
                              <span style={{ fontWeight: 500 }}>
                                {formik.values.custom_per}%
                              </span>{" "}
                              margin is $
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - parseFloat(formik.values.custom_per) / 100)
                              ).toFixed(2)}{" "}
                            </Text>
                          </Box>
                        ) : null}
                      </>
                    ) : null} */}

                    {!formik.errors.cost &&
                    null != formik.values.cost &&
                    "" != formik.values.cost ? (
                      <>
                        <Box>
                          {/* <Text
                            color={"#010048"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            Price of individual Unit:{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                                textDecorationLine: "underline",
                              }}
                            >
                              $
                              {(parseFloat(formik.values.cost)).toFixed(2)}
                            </span>
                          </Text> */}
                          <Text
                            color={"rgba(1, 0, 72, 0.80)"}
                            fontWeight={300}
                            fontSize={"0.94rem"}
                            mt={{ base: "0.88rem", md: "0.88rem" }}
                            mb={{ base: "0.88rem", md: "0.88rem" }}
                          >
                            *Selling Price Recommendations at various margins*
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            20%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                parseFloat(formik.values.cost).toFixed(2) /
                                (1 - 20 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            25%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                parseFloat(formik.values.cost).toFixed(2) /
                                (1 - 25 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            30%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                parseFloat(formik.values.cost).toFixed(2) /
                                (1 - 30 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            35%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                parseFloat(formik.values.cost).toFixed(2) /
                                (1 - 35 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                        </Box>
                        <Box>
                          <PosLable
                            label={true}
                            name={"Custom Margin"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="custom_per"
                            // placeholder={"Enter a Custom Margin"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.custom_per || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "custom_per",
                                twofix(formik.values.custom_per)
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.custom_per &&
                              formik.errors.custom_per
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.custom_per &&
                            formik.errors.custom_per ? (
                              <span>{formik.errors.custom_per}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {!formik.errors.custom_per &&
                        null != formik.values.custom_per &&
                        "" != formik.values.custom_per ? (
                          <Box>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={300}
                              fontSize={"0.94rem"}
                            >
                              *Suggested Selling Price at{" "}
                              <span style={{ fontWeight: 500 }}>
                                {formik.values.custom_per}%
                              </span>{" "}
                              margin is $
                              {(
                                parseFloat(formik.values.cost).toFixed(2) /
                                (1 - parseFloat(formik.values.custom_per) / 100)
                              ).toFixed(2)}{" "}
                            </Text>
                          </Box>
                        ) : null}
                      </>
                    ) : null}

                    {/* <Box pt={"2rem"}>
                      <Divider h={"0.05rem"} />
                    </Box> */}

                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: cashDiscountPercentage > 0 ? "50%" : "100%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={"Retail Price"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="sale_price"
                          // placeholder={"Enter a Sale Price"}
                          // handleInputChange={formik.handleChange}
                          handleInputChange={(e) => {
                            formik.setFieldValue(
                              "sale_price",
                              e?.target?.value
                            );
                            formik.setFieldValue(
                              "cash_price",
                              twofix(
                                reverseCalculateDiscountedPrice(
                                  e?.target?.value,
                                  cashDiscountPercentage
                                )
                              )
                            );
                          }}
                          inputValue={formik.values.sale_price || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "sale_price",
                              twofix(formik.values.sale_price)
                            );
                            formik.setFieldValue(
                              "cash_price",
                              twofix(
                                reverseCalculateDiscountedPrice(
                                  formik.values?.sale_price,
                                  cashDiscountPercentage
                                )
                              )
                            );
                            const marginSalePrice = twofix(
                              formik.values.sale_price
                            );
                            // Check modifiedViewDatas and perform calculation if base_qty > 0
                            checkAndUpdatePrice(marginSalePrice);
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.sale_price &&
                            formik.errors.sale_price
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.sale_price &&
                          formik.errors.sale_price ? (
                            <span>{formik.errors.sale_price}</span>
                          ) : null}
                        </Text>
                      </Box>
                      {cashDiscountPercentage > 0 ? (
                        <Box
                          mr={!isMobile ? 2.5 : 0}
                          w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                            xl: "50%",
                          }}
                        >
                          <PosLable
                            label={true}
                            name={"Cash Price"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="cash_price"
                            // placeholder={"Enter a Sale Price"}
                            // handleInputChange={formik.handleChange}
                            handleInputChange={(e) => {
                              formik.setFieldValue(
                                "cash_price",
                                e?.target?.value
                              );
                              formik.setFieldValue(
                                "sale_price",
                                twofix(
                                  calculateDiscountedPrice(
                                    e?.target?.value,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                            }}
                            inputValue={formik.values.cash_price || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "cash_price",
                                twofix(formik.values.cash_price)
                              );
                              formik.setFieldValue(
                                "sale_price",
                                twofix(
                                  calculateDiscountedPrice(
                                    formik.values?.cash_price,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                              const marginSalePrice = twofix(
                                formik.values.sale_price
                              );
                              // Check modifiedViewDatas and perform calculation if base_qty > 0
                              checkAndUpdatePrice(marginSalePrice);
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.cash_price &&
                              formik.errors.cash_price
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.cash_price &&
                            formik.errors.cash_price ? (
                              <span>{formik.errors.cash_price}</span>
                            ) : null}
                          </Text>
                        </Box>
                      ) : null}
                    </Box>
                    <Box className="card flex justify-content-center">
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Reward Points"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="These are the points a customer will get on purchasing the product."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      <PosInput
                        id="reward_points"
                        // placeholder={"Enter a Reward Points"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.reward_points || ""}
                        handleBlur={formik.handleBlur}
                        posNumberInput={true}
                        // inputType={"text"}
                        inputError={
                          formik.touched.reward_points &&
                          formik.errors.reward_points
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.reward_points &&
                        formik.errors.reward_points ? (
                          <span>{formik.errors.reward_points}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box className="card flex justify-content-center">
                      <PosLable
                        label={true}
                        name={"Taxes"}
                        marginTop={"1rem"}
                      />
                      <PosDropDown
                        options={taxes}
                        value={formik?.values?.selectedTaxes}
                        onChange={(e) =>
                          formik.setFieldValue("selectedTaxes", e.value)
                        }
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="--Select Taxes--"
                        lableAvailable={true}
                        width={"100%"}
                        height={"2.5rem"}
                        className="w-full md:w-20rem"
                        // display={"chip"}
                        maxSelectedLabels={2}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.selectedTaxes &&
                        formik.errors.selectedTaxes ? (
                          <span>{formik.errors.selectedTaxes}</span>
                        ) : null}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : currentTab === 1 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Additional Attributes
                      </Heading>
                    </Box>
                    {disableAdditionalAttributes ? (
                      <Heading
                        as={"h4"}
                        lineHeight="normal"
                        fontStyle={"normal"}
                        fontSize={"0.94rem"}
                        color={Colors.posViewTextColor}
                        fontWeight={"400"}
                      >
                        <Highlight
                          query={[
                            "Modifier Sets",
                            "Sold by Weight",
                            "Prompt Quantity",
                            "Variable Price",
                          ]}
                          styles={{
                            px: "0",
                            py: "0",
                            rounded: "full",
                            bg: "teal.100",
                          }}
                        >
                          Note:- Once you add Related Units then you cannot
                          assign Modifier Sets or Sold by Weight or Prompt
                          Quantity or Variable Price.
                        </Highlight>
                      </Heading>
                    ) : (
                      ""
                    )}
                    {!formik.values.sold_by_weight &&
                    !formik.values.prompt_qty &&
                    !formik.values.is_variable_price ? (
                      <>
                        <Box className="card flex justify-content-center">
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Select Modifier Sets"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="This feature allows for customization of the product, which is helpful in ensuring accurate orders for restaurants."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                          </Flex>
                          <PosDropDown
                            options={modifierSets}
                            value={formik?.values?.selectedModifierSets}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "selectedModifierSets",
                                e.value
                              )
                            }
                            multiSelect={true}
                            optionLabel="label"
                            placeholder="--Select Modifier Sets--"
                            lableAvailable={true}
                            disabled={disableAdditionalAttributes}
                            width={"100%"}
                            height={"2.5rem"}
                            className="w-full md:w-20rem"
                            // display={"chip"}
                            maxSelectedLabels={2}
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedModifierSets &&
                            formik.errors.selectedModifierSets ? (
                              <span>{formik.errors.selectedModifierSets}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {/* <Box pt={"2rem"}>
                            <Divider h={"0.05rem"} />
                          </Box> */}
                      </>
                    ) : null}
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Age Verification"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="Set a minimum lit that needs to be matched for the sale of the good. Such products when scanned will ring up an age verification page."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                        <Spacer />
                        <PosSwitchButton
                          id="age_verification"
                          switchValue={formik.values.age_verification}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                          direction={"row"}
                          w={"44px"}
                          height={"22px"}
                          flexShrink={"0"}
                        >
                          <PosSwitchButton
                            id="age_verification"
                            switchValue={formik.values.age_verification}
                            onChange={formik.handleChange}
                          />
                        </Stack> */}
                      {/* <Text
                          mt={{ base: "0.94rem", md: "0.94rem" }}
                          color={Colors.taxCreateExemptEbt}
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          Turn on, to allow minimum age for customers to make
                          purchase of the goods, products when scanned will ring
                          up an age verification page
                        </Text> */}
                    </Box>
                    {formik.values.age_verification ? (
                      <Box pt={"1rem"}>
                        <PosInput
                          id="min_age_verification"
                          placeholder={"Enter minimum age *"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.min_age_verification || ""}
                          handleBlur={formik.handleBlur}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.min_age_verification &&
                            formik.errors.min_age_verification
                          }
                          darkPlaceholder={true}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.min_age_verification &&
                          formik.errors.min_age_verification ? (
                            <span>{formik.errors.min_age_verification}</span>
                          ) : null}
                        </Text>
                      </Box>
                    ) : null}
                    {(formik.values.selectedModifierSets != null &&
                      formik.values.selectedModifierSets != undefined &&
                      formik.values.selectedModifierSets.length > 0) ||
                    formik.values.prompt_qty ? null : (
                      <>
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Sold By Weight"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="Converts the price into Per pound price. The product gets weighed on the scale and priced accordingly. Mostly used for Produce and Deli."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                            <Spacer />
                            <PosSwitchButton
                              id="sold_by_weight"
                              disabled={disableAdditionalAttributes}
                              switchValue={formik.values.sold_by_weight}
                              // onChange={formik.handleChange}
                              onChange={(value) => {
                                formik.handleChange(value);
                                formik.setFieldValue(
                                  "selectedTareContainers",
                                  []
                                );
                              }}
                              mt={"1rem"}
                              ml={"1.5rem"}
                            />
                          </Flex>
                          {/* <Stack
                              direction={"row"}
                              w={"44px"}
                              height={"22px"}
                              flexShrink={"0"}
                            >
                              <PosSwitchButton
                                id="sold_by_weight"
                                switchValue={formik.values.sold_by_weight}
                                // onChange={formik.handleChange}
                                onChange={(value) => {
                                  formik.handleChange(value);
                                  formik.setFieldValue(
                                    "selectedTareContainers",
                                    []
                                  );
                                }}
                              />
                            </Stack> */}
                        </Box>
                        {formik.values.sold_by_weight == true ? (
                          <Box mt={{ base: "0.94rem", md: "0.94rem" }}>
                            <PosDropDown
                              options={tareContainers}
                              value={formik?.values?.selectedTareContainers}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "selectedTareContainers",
                                  e.value
                                )
                              }
                              multiSelect={true}
                              optionLabel="label"
                              placeholder="--Select Tare Containters--"
                              lableAvailable={true}
                              width={"100%"}
                              height={"2.5rem"}
                              className="w-full md:w-20rem"
                              // display={"chip"}
                              maxSelectedLabels={2}
                            />
                            <Text color={Colors.errorColor}>
                              {formik.touched.selectedTareContainers &&
                              formik.errors.selectedTareContainers ? (
                                <span>
                                  {formik.errors.selectedTareContainers}
                                </span>
                              ) : null}
                            </Text>
                          </Box>
                        ) : null}
                      </>
                    )}

                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Exclude EBT"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="If clicked on, then the item can not be purchased by using EBT."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                        <Spacer />
                        <PosSwitchButton
                          id="exclude_ebt"
                          switchValue={formik.values.exclude_ebt}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                          direction={"row"}
                          w={"44px"}
                          height={"22px"}
                          flexShrink={"0"}
                        >
                          <PosSwitchButton
                            id="exclude_ebt"
                            switchValue={formik.values.exclude_ebt}
                            onChange={formik.handleChange}
                          />
                        </Stack> */}
                      {/* <Text
                          mt={{ base: "0.94rem", md: "0.94rem" }}
                          color={Colors.taxCreateExemptEbt}
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          If turned on, item cannot be purchased by using EBT
                        </Text> */}
                    </Box>
                    {formik.values.selectedModifierSets != null &&
                    formik.values.selectedModifierSets != undefined &&
                    formik.values.selectedModifierSets.length > 0 ? null : (
                      <>
                        {!formik.values.sold_by_weight ? (
                          <Box>
                            <Flex flexDirection={"row"}>
                              <PosLable
                                label={true}
                                name={"Prompt Quantity"}
                                marginTop={"1rem"}
                              />
                              <Tippy
                                content="Prompt a quantity to choose from 1 to 10. Helpful for goods that are sold in multiple units at once( lemons, oranges)."
                                interactive={true}
                                maxWidth={390}
                                placement="top"
                                animation="fade"
                                inertia={true}
                                moveTransition="transform 0.2s ease-out"
                                theme="tomato"
                              >
                                <Image
                                  src={toolTip}
                                  alt="Tooltip"
                                  mt={"1rem"}
                                  ml={"0.3rem"}
                                />
                              </Tippy>
                              <Spacer />
                              <PosSwitchButton
                                id="prompt_qty"
                                disabled={disableAdditionalAttributes}
                                switchValue={formik.values.prompt_qty}
                                onChange={formik.handleChange}
                                mt={"1rem"}
                                ml={"1.5rem"}
                              />
                            </Flex>
                            {/* <Stack
                                direction={"row"}
                                w={"44px"}
                                height={"22px"}
                                flexShrink={"0"}
                              >
                                <PosSwitchButton
                                  id="prompt_qty"
                                  switchValue={formik.values.prompt_qty}
                                  onChange={formik.handleChange}
                                />
                              </Stack> */}
                          </Box>
                        ) : null}
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Variable Price"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="Asks for a new price every time selected. Useful for products with Variable prices such as handicrafts."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                            <Spacer />
                            <PosSwitchButton
                              id="is_variable_price"
                              disabled={disableAdditionalAttributes}
                              switchValue={formik.values.is_variable_price}
                              onChange={formik.handleChange}
                              mt={"1rem"}
                              ml={"1.5rem"}
                            />
                          </Flex>
                          {/* <Stack
                              direction={"row"}
                              w={"44px"}
                              height={"22px"}
                              flexShrink={"0"}
                            >
                              <PosSwitchButton
                                id="is_variable_price"
                                switchValue={formik.values.is_variable_price}
                                onChange={formik.handleChange}
                              />
                            </Stack> */}
                        </Box>
                      </>
                    )}
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Exclude Kitchen Printing/ KDS"}
                          marginTop={"1rem"}
                        />
                        <Spacer />
                        <PosSwitchButton
                          id="is_exclude_kds"
                          switchValue={formik.values.is_exclude_kds}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                          direction={"row"}
                          w={"44px"}
                          height={"22px"}
                          flexShrink={"0"}
                        >
                          <PosSwitchButton
                            id="is_exclude_kds"
                            switchValue={formik.values.is_exclude_kds}
                            onChange={formik.handleChange}
                          />
                        </Stack> */}
                    </Box>
                  </VStack>
                </Box>
              </Box>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Customize Point of Sale Button
                      </Heading>
                    </Box>
                    {/* <Box mt={{ base: "2rem", md: "2rem" }}>
                      <PosImageCropper
                        parentCallback={handleCallback}
                        imgUploadLabel="Product Image"
                        canvasWidth="14.75rem"
                        canvasHeight="14.75rem"
                        cropperWidth="96"
                        cropperHeight="79"
                        // screen="product"
                      />
                    </Box> */}
                    <Box mt={{ base: "1rem", md: "1rem" }}>
                      <PosImageCropper
                        parentCallback={handleCallback}
                        imgUploadLabel="Product Image"
                        canvasWidth="14.75rem"
                        canvasHeight="14.75rem"
                        cropperWidth={236}
                        cropperHeight={193}
                        cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                        cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                        cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                        cropperMaxHeight={193} // Maximum height of the crop area when edit open at time full cropper display
                        handleImage={handleImage}
                        image={prodImage}
                      />
                    </Box>
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Choose Background Color"}
                        marginTop={"1rem"}
                      />
                      <MuiColorInput
                        id="background_color"
                        format="hex"
                        value={backgroundColor}
                        onChange={handleChangeBackgroundColor}
                        // disabled={true}
                      />
                    </Box> */}
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Choose Text Color"}
                        marginTop={"1rem"}
                      />
                      <MuiColorInput
                        id="text_color"
                        format="hex"
                        value={textColor}
                        onChange={handleChangeTextColor}
                      />
                    </Box> */}
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Choose Background Color"}
                          marginTop={"1rem"}
                        />
                        <MuiColorInput
                          id="background_color"
                          format="hex"
                          value={backgroundColor}
                          onChange={handleChangeBackgroundColor}
                          // disabled={true}
                        />
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Choose Text Color"}
                          marginTop={"1rem"}
                        />
                        <MuiColorInput
                          id="text_color"
                          format="hex"
                          value={textColor}
                          onChange={handleChangeTextColor}
                        />
                      </Box>
                    </Box>
                    <PosLable
                      label={true}
                      name={"POS Button Preview"}
                      marginTop={"1rem"}
                    />
                    <Box
                      position="relative"
                      // h="14rem"
                      // w="16.37rem"
                      mt={{ base: "1.69rem", md: "1.69rem" }}
                    >
                      {!base64OfUploadedImg ? (
                        <>
                          <Box
                            style={{ position: "relative" }}
                            // display={"flex"}
                            // justifyContent={"center"}
                          >
                            {/* Avatar view */}
                            <Box
                              style={{
                                width: "225px",
                                height: "180px",
                                // backgroundColor: item.backgroundColor_hexcode ? item.backgroundColor_hexcode :  'rgba(217, 217, 217, 0.85)', // Semi-transparent background
                                backgroundColor: backgroundColor,
                                justifyContent: "center",
                                display: "flex",
                                alignSelf: "center",
                                alignContent: "center",
                                alignItems: "center",
                                borderRadius: "7px",
                              }}
                            >
                              <Text
                                style={{
                                  marginBottom: "30px",
                                  color: textColor,
                                  fontSize: "xl",

                                  textAlign: "center",
                                }}
                              >
                                {formattedName}
                              </Text>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                position: "absolute",
                                bottom: 0,
                                backgroundColor: "#D9D9D9",
                                borderBottomLeftRadius: "7px",
                                borderBottomRightRadius: "7px",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                width: "225px",
                                height: "55px",
                              }}
                            >
                              <Text
                                fontSize={"sm"}
                                fontWeight={
                                  formattingName.length > 27 ? "thin" : "500"
                                }
                              >
                                {formattingName || "Product Name"}
                              </Text>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          style={{ position: "relative" }}
                          // display={"flex"}
                          // justifyContent={"center"}
                        >
                          <Image
                            style={{
                              width: "225px",
                              height: "180px",
                              borderTopLeftRadius: "7px",
                              borderTopRightRadius: "7px",
                              justifyContent: "center",

                              borderRadius: "7px",
                            }}
                            src={base64OfUploadedImg}
                          />
                          <Box
                            style={{
                              display: "flex",
                              position: "absolute",
                              bottom: 0,
                              backgroundColor: "rgba(217, 217, 217, 0.85)",
                              borderBottomLeftRadius: "7px",
                              borderBottomRightRadius: "7px",
                              alignItems: "center",
                              justifyContent: "center",
                              alignSelf: "center",
                              width: "225px",
                              height: "55px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "sm",
                                padding: "0.5rem",
                                // textAlign: "center",
                                color: "#010048",
                              }}
                              fontWeight={
                                formattingName.length > 27 ? "thin" : "500"
                              }
                            >
                              {formattingName || "Product Name"}
                            </Text>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : currentTab === 2 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Unit of Measure
                      </Heading>
                    </Box>
                    {formik?.values?.selectedModifierSets?.length > 0 ||
                    formik.values?.sold_by_weight == true ||
                    formik.values?.prompt_qty == true ||
                    formik.values?.is_variable_price == true ? (
                      <Heading
                        as={"h4"}
                        lineHeight="normal"
                        fontStyle={"normal"}
                        fontSize={"0.94rem"}
                        color={Colors.posViewTextColor}
                        fontWeight={"400"}
                      >
                        <Highlight
                          query={[
                            "Modifier Sets",
                            "Sold by Weight",
                            "Prompt Quantity",
                            "Variable Price",
                          ]}
                          styles={{
                            px: "0",
                            py: "0",
                            rounded: "full",
                            bg: "teal.100",
                          }}
                        >
                          Note: You are unable to assign Related Units once you
                          have added Modifier Sets or Sold by Weight or Prompt
                          Quantity or Variable Price.
                        </Highlight>
                      </Heading>
                    ) : (
                      ""
                    )}
                    {/* {viewSelectedProducts && viewSelectedProducts.length > 0 && ( */}
                    <Box
                      borderRadius="0.63rem"
                      mt={3}
                      bg={Colors.posPageDataBackground}
                      boxShadow={
                        "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowX: "auto",
                      }}
                    >
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead
                            alignitems="start"
                            // hidden={headerHide}
                            sx={{ backgroundColor: Colors.modifierTable }}
                          >
                            <TableRow>
                              {columnUnitsViewNames?.map((column, index) => (
                                <TableCell
                                  key={index}
                                  // color={Colors.primeposTextInfo}
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "0.94rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-0.01rem",
                                    fontStyle: "normal",
                                    textAlign: column.textAlign || "center",
                                    maxHeight: "42rem",
                                    color: "#010048",
                                    // pl:"3.56rem",
                                    // width:
                                    //   column.width == "" ? undefined : column.width,
                                  }}
                                >
                                  <Flex
                                    flexDirection={"row"}
                                    style={{
                                      justifyContent:
                                        column?.columnNames !=
                                          "Related Units" && "center",
                                    }}
                                  >
                                    {column.columnNames}
                                  </Flex>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {modifiedViewDatas.length > 0 ? (
                              modifiedViewDatas.map((row, i) => {
                                let isIdEmpty = !row.id;
                                return (
                                  <TableRow
                                    key={i}
                                    // sx={{
                                    //   "&:last-child td, &:last-child th": { border: 0 },
                                    // }}
                                  >
                                    <TableCell>
                                      {isIdEmpty ? (
                                        <Flex flexDirection={"row"}>
                                          {/* <Search2Icon mt={3} mr={3} /> */}
                                          <ThemeProvider theme={Mtheme}>
                                            <Autocomplete
                                              size="small"
                                              filterOptions={(x) => x}
                                              id={`autoselect-${i}`}
                                              sx={{ width: 200 }}
                                              open={open}
                                              onOpen={() => {
                                                setOpen(true);
                                              }}
                                              onClose={() => {
                                                setOpen(false);
                                              }}
                                              value={unit}
                                              onChange={(event, newValue) => {
                                                // Set the unit of measure based on user selection
                                                onSetUnitofMeasureSingleLine(
                                                  newValue
                                                );
                                              }}
                                              onInputChange={(
                                                event,
                                                newInputValue
                                              ) => {
                                                setUnit(newInputValue);
                                              }}
                                              getOptionLabel={(option) =>
                                                option?.label || ""
                                              }
                                              options={unitOfMeasures}
                                              renderOption={(props, option) => (
                                                <li
                                                  {...props}
                                                  key={option?.label}
                                                >
                                                  {option?.label}
                                                </li>
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Select"
                                                />
                                              )}
                                              disabled={
                                                formik?.values
                                                  ?.selectedModifierSets
                                                  ?.length > 0 ||
                                                formik.values?.sold_by_weight >
                                                  0 ||
                                                formik.values?.prompt_qty > 0 ||
                                                formik.values
                                                  ?.is_variable_price > 0
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </ThemeProvider>
                                        </Flex>
                                      ) : (
                                        <VStack align="left">
                                          <Box
                                            style={{
                                              fontSize: "0.94rem",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              lineHeight: "normal",
                                              letterSpacing: "-0.01rem",
                                              color: "#010048",
                                            }}
                                          >
                                            {row.label}
                                          </Box>
                                        </VStack>
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="base_qty"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        ref={(el) =>
                                          (inputRefs.current[i] = el)
                                        }
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "base_qty"
                                          )
                                        }
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value.trim();
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "base_qty"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].base_qty
                                            : row.base_qty) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="number"
                                        isInvalid={
                                          modifiedViewDatas[i]?.baseQtyErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.baseQtyErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {
                                              modifiedViewDatas[i]
                                                ?.baseQtyErrors
                                            }
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="pricing"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        // ref={(el) => (inputRefs.current[i] = el)}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "pricing"
                                          )
                                        }
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value.trim();
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "pricing"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].pricing
                                            : row.pricing) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="number"
                                        isInvalid={
                                          modifiedViewDatas[i]?.pricingErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.pricingErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {
                                              modifiedViewDatas[i]
                                                ?.pricingErrors
                                            }
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        disabled={true}
                                        id="margin"
                                        htmlSize={4}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "margin"
                                          )
                                        }
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value.trim();
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "margin"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].margin
                                            : "N.A.") || "N.A."
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="text"
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="upc"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        // ref={(el) => (inputRefs.current[i] = el)}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) => {
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "upc"
                                          );
                                        }}
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "upc"
                                          );
                                          // const newValue = e.target.value.trim();
                                          // handleInputChangeUOM(e, row.id, "upc");
                                          // Check if the new value causes any duplicate error
                                          // const isUPCConsistent = modifiedViewDatas.some(item => item.upc === newValue && item.id !== row.id);
                                          // if (isUPCConsistent) {
                                          //   modifiedViewDatas[i].upcErrors = "UPC is already used.";
                                          // } else {
                                          //   modifiedViewDatas[i].upcErrors = "";
                                          // }
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].upc
                                            : row.upc) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="string"
                                        isInvalid={
                                          modifiedViewDatas[i]?.upcErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.upcErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {modifiedViewDatas[i]?.upcErrors}
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        disabled={true}
                                        id="uom_in_stock"
                                        htmlSize={4}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "in_stock"
                                          )
                                        }
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value.trim();
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "in_stock"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].in_stock
                                            : "N.A.") || "N.A."
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="text"
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      {isIdEmpty ? null : (
                                        <DeleteIcon
                                          color={Colors.posCancleButtonMuiRed}
                                          style={{
                                            width: "1.13rem",
                                            height: "1.13rem",
                                            flexShrink: 0,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => deleteById(row.id)}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell>
                                  <Flex flexDirection={"row"}>
                                    {/* <Search2Icon mt={3} mr={3} /> */}
                                    <ThemeProvider theme={Mtheme}>
                                      <Autocomplete
                                        options={unitOfMeasures}
                                        value={unit}
                                        size="small"
                                        filterOptions={(x) => x}
                                        // id={`autoselect-${i}`}
                                        sx={{ width: 200 }}
                                        open={open}
                                        onOpen={() => {
                                          setOpen(true);
                                        }}
                                        onClose={() => {
                                          setOpen(false);
                                        }}
                                        onChange={(event, newValue) => {
                                          // Set the unit of measure based on user selection
                                          onSetUnitofMeasureSingleLine(
                                            newValue
                                          );
                                        }}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          setUnit(newInputValue);
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.label || ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option?.id === value?.id
                                        }
                                        renderOption={(props, option) => (
                                          <li {...props} key={option?.label}>
                                            {option?.label}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select"
                                          />
                                        )}
                                        disabled={
                                          formik.values?.selectedModifierSets
                                            ?.length > 0 ||
                                          formik.values?.sold_by_weight > 0 ||
                                          formik.values?.prompt_qty > 0 ||
                                          formik.values?.is_variable_price > 0
                                            ? true
                                            : false
                                        }
                                      />
                                    </ThemeProvider>
                                  </Flex>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    {/* )} */}
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Box className="card flex justify-content-center">
                          <PosLable
                            requiredLabel={true}
                            name={"Default Selling Unit"}
                            marginTop={"1rem"}
                          />
                          <PosDropDown
                            id="defaultSelectedUnit"
                            options={defaultSelectedUnit}
                            value={selectedDefaultSellingUnit}
                            onChange={handleOptionSelectDefaultSellingUnit}
                            onBlur={(e) => {
                              formik.setFieldTouched(
                                "selectedDefaultSellingUnit"
                              );
                              formik.handleBlur(e);
                            }}
                            // onBlur={() => formik.handleBlur("selectedDefaultSellingUnit")}
                            lableAvailable={true}
                            optionLabel="label"
                            placeholder="Default Selling Unit"
                            width={"100%"}
                            className="w-full md:w-20rem"
                          />

                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedDefaultSellingUnit &&
                            formik.errors.selectedDefaultSellingUnit ? (
                              <span>
                                {formik.errors.selectedDefaultSellingUnit}
                              </span>
                            ) : null}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Box className="card flex justify-content-center">
                          <PosLable
                            requiredLabel={true}
                            name={"Default Purchasing Unit"}
                            marginTop={"1rem"}
                          />
                          <PosDropDown
                            id="defaultSelectedPurchasingUnit"
                            options={defaultSelectedUnit}
                            value={selectedDefaultPurchasingUnit}
                            onChange={handleOptionSelectDefaultPurchasingUnit}
                            onBlur={(e) => {
                              formik.setFieldTouched(
                                "selectedDefaultPurchasingUnit"
                              );
                              formik.handleBlur(e);
                            }}
                            // onBlur={() => formik.handleBlur("selectedDefaultPurchasingUnit")}
                            lableAvailable={true}
                            optionLabel="label"
                            placeholder="Default Purchasing Unit"
                            width={"100%"}
                            className="w-full md:w-20rem"
                          />

                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedDefaultPurchasingUnit &&
                            formik.errors.selectedDefaultPurchasingUnit ? (
                              <span>
                                {formik.errors.selectedDefaultPurchasingUnit}
                              </span>
                            ) : null}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Online Order Product Attributes
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Ordering Price"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="online_ordering_price"
                        // placeholder={"Enter an Online Ordering Price"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.online_ordering_price || ""}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "online_ordering_price",
                            twofix(formik.values.online_ordering_price)
                          );
                        }}
                        precision={2}
                        posNumberInput={true}
                        // inputType={"text"}
                        inputError={
                          formik.touched.online_ordering_price &&
                          formik.errors.online_ordering_price
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.online_ordering_price &&
                        formik.errors.online_ordering_price ? (
                          <span>{formik.errors.online_ordering_price}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Product Description"}
                        // mt="0.31rem"
                        marginTop={"1rem"}
                      />
                      <PosTextArea
                        id="description"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        inputerror={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <span>{formik.errors.description}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Featured Product"}
                          marginTop={"1rem"}
                        />
                        <PosSwitchButton
                          id="online_is_featured_product"
                          switchValue={formik.values.online_is_featured_product}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                        direction={"row"}
                        w={"44px"}
                        height={"22px"}
                        flexShrink={"0"}
                      >
                        <PosSwitchButton
                          id="online_is_featured_product"
                          switchValue={formik.values.online_is_featured_product}
                          onChange={formik.handleChange}
                        />
                      </Stack> */}
                      {/* <Text
                        mt={{ base: "0.94rem", md: "0.94rem" }}
                        color={Colors.taxCreateExemptEbt}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Turn on, to allow product feature on online store page
                      </Text> */}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          )}
        </Flex>
      </Box>
    </PosDrawer>
  );
};

export default WithRouter(ProductCreate);
