import moment from "moment";
/**
 * tofixtwo().
 *
 * this function is used to do toFixed(2) after every calculation in order and order item calculation.
 *
 * @param {string or number} number Which number's toFixed(2) we have to do that number.
 *
 * @return {type} toFixed(2) value of requested value.
 */
export var twofix = (number) => {
  if (null !== number && "" !== number && !isNaN(number)) {
    return parseFloat(number).toFixed(2);
  }
};
export var twofixs = (number) => {
  if (null !== number && !isNaN(number)) {
    return "$" + parseFloat(number).toFixed(2);
  }
  return "$0.00";
};

export var reportDateFormate = (str) => {
  var date = new Date(str);
  var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var sec = ("0" + date.getSeconds()).slice(-2);
  return (
    mnth +
    "/" +
    day +
    "/" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    sec
  );
};

export var startOfTodaysDay = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00:000
  return today;
};

export var endOfTodaysDay = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 0); // Set time to 00:00:00:000
  return today;
};

export var commonDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY hh:mm A");
  return today;
};

export var onlyDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY");
  return today;
};

export var toFixTwoForDiscountCalculate = (num) => {
  var d = 2 || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

// Calculate Retail Price from Cash Price by location cash discount percentage.
export var calculateDiscountedPrice = (salePrice, discountPercentage) => {
  if (!salePrice && !discountPercentage) return "";

  const price = parseFloat(salePrice);
  const discount = parseFloat(discountPercentage);

  if (isNaN(price) || isNaN(discount)) return "";

  if (price && !discount) return price.toFixed(2);

  // const discountedPrice = (price + (price * (discount / 100)));
  var total = /// twofix issue of 2.5*79.29 = 192.225 but twoFix in this code will be 192.22 instead of 192.23
  toFixTwoForDiscountCalculate(
    parseFloat(price) * (parseFloat(discount) / 100)
  ).toFixed(2);
  return toFixTwoForDiscountCalculate(
    parseFloat(price) + parseFloat(total)
  ).toFixed(2);
};

// Reverse Calculate Cash Price from Retail Price by location cash discount percentage.
export var reverseCalculateDiscountedPrice = (
  salePrice,
  discountPercentage
) => {
  if (!salePrice && !discountPercentage) return "";

  const price = parseFloat(salePrice);
  const discount = parseFloat(discountPercentage);

  if (isNaN(price) || isNaN(discount)) return "";

  if (price && !discount) return price.toFixed(2);

  // const discountedPrice = ((price * 100) / (discount + 100));
  const discountedPrice = price / (1 + discount / 100);
  // const discountedPrice = price - (price * discount / 100);

  return discountedPrice.toFixed(2);
};
