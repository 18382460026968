import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function inventorySummaryReportAndExport({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).getreport === "true") {
      
      const payload = JSON.stringify({
        data: JSON.parse(Object.fromEntries(formData).data),
        query: JSON.parse(Object.fromEntries(formData).query),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.INVENTORY_SUMMARY_REPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.INVENTORY_SUMMARY_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      const payload = JSON.stringify({
        location: JSON.parse(Object.fromEntries(formData).location),
        vendor: JSON.parse(Object.fromEntries(formData).vendor),
        max_in_stock: Object.fromEntries(formData).max_in_stock,
        filter: Object.fromEntries(formData).filter,
        min_in_stock: Object.fromEntries(formData).min_in_stock,
        type: Object.fromEntries(formData).type,
      });
     
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.INVENTORY_SUMMARY_REPORT_EXPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.INVENTORY_SUMMARY_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllLocationsAndVendors({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ALL_LOCATION_AND_VENDOR_API_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.INVENTORY_SUMMARY_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    });
  }