import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";
import Tippy from '@tippyjs/react';
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useParams, useSubmit } from "react-router-dom";
import 'tippy.js/dist/tippy.css'; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from '../../../assets/images/tool_tip_hover_icon.svg';
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";
import { SelectButton } from "primereact/selectbutton";

const LocationAccountSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [state, setState] = useState({ formData: {} });
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [taxes, setTax] = useState([]);
  const [setSettings, setLocationAdditionalSettings] = useState([]);
  const { id } = useParams();
  const [viewFlag, setViewFlag] = useState(false);
  const scheduledOptions = [
    { label: "Dual Pricing", value: "dual_pricing" },
    { label: "Normal", value: "normal" },
  ];

  useEffect(() => {
    if (props.isOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isOpen]);

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTax(response.data?.data?.tax);
            setSelectedTaxes(response.data?.data?.selected_taxes);

            response.data?.data?.locationSettings.map((dt) => {
              // for (const [key, value] of Object.entries(dt)) {
              if (dt.key != "extra_item_threshold" && dt.key != "ideal_time"
                &&
                dt.key != "merchant_fee_type" &&
                dt.key != "cash_discount_percentage"
              ) {
                if (dt.value == "yes") {
                  dt.value = true;
                } else if (dt.key == "clockout") {
                  if (dt.value == "1" || dt.value == 1) {
                    dt.value = true;
                  } else {
                    dt.value = false;
                  }
                } else {
                  dt.value = false;
                }
              }
              // }
            });

            setLocationAdditionalSettings(
              response.data?.data?.locationSettings
            );
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            setLocationAdditionalSettings([]);
            setSelectedTaxes([]);
            setTax([]);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLocationAdditionalSettings([]);
          setSelectedTaxes([]);
          setTax([]);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setLocationAdditionalSettings([]);
      setSelectedTaxes([]);
      setTax([]);
      myContext.handleLoading(false);
    }
  };

  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item) => item.key == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      clockout: findAndReturnValue(setSettings, "clockout", "value"),
      logout_order: findAndReturnValue(setSettings, "logout_order", "value"),
      logout_time: findAndReturnValue(setSettings, "logout_time", "value"),
      ideal_time:
        findAndReturnValue(setSettings, "ideal_time", "value") != null
          ? parseInt(findAndReturnValue(setSettings, "ideal_time", "value"))
          : "",
      latest_update_notify: findAndReturnValue(
        setSettings,
        "latest_update_notify",
        "value"
      )==null?true:findAndReturnValue(
        setSettings,
        "latest_update_notify",
        "value"
      ),
      auto_sync: findAndReturnValue(setSettings, "auto_sync", "value")==null?true:findAndReturnValue(setSettings, "auto_sync", "value"),
      favorites: findAndReturnValue(setSettings, "favorites", "value")==null?true:findAndReturnValue(setSettings, "favorites", "value"),
      ebt_enable: findAndReturnValue(setSettings, "ebt_enable", "value"),
      other_type: findAndReturnValue(setSettings, "other_type", "value"),
      credit_account: findAndReturnValue(
        setSettings,
        "credit_account",
        "value"
      )==null?true:findAndReturnValue(
        setSettings,
        "credit_account",
        "value"
      ),
      gift_card: findAndReturnValue(setSettings, "gift_card", "value")==null?true:findAndReturnValue(
        setSettings,
        "gift_card",
        "value"
      ),
      extra_item_threshold:
        findAndReturnValue(setSettings, "extra_item_threshold", "value") != null
          ? parseFloat(
              findAndReturnValue(setSettings, "extra_item_threshold", "value")
            ).toFixed(2)
          : 0,
      selectedTaxes: selectedTaxes,
      merchant_fee_type:
        findAndReturnValue(setSettings, "merchant_fee_type", "value") != null
          ? findAndReturnValue(
              setSettings,
              "merchant_fee_type",
              "value"
            ).toLowerCase()
          : "normal",
      cash_discount_percentage:
        findAndReturnValue(setSettings, "cash_discount_percentage", "value") !=
        null
          ? parseFloat(
              findAndReturnValue(
                setSettings,
                "cash_discount_percentage",
                "value"
              )
            )
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      extra_item_threshold: Yup.number()
        .typeError(Constants.EXTRA_ITEM_THRESHOLD_VALID)
        // .required(Constants.EXTRA_ITEM_THRESHOLD_REQUIRED)
        .min(0, Constants.EXTRA_ITEM_THRESHOLD_MIN)
        .max(999999.99, Constants.EXTRA_ITEM_THRESHOLD_MAX),
      ideal_time: Yup.number().when("logout_time", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.IDEAL_TIME_REQUIRED)
            .typeError(Constants.IDEAL_TIME_VALID)
            .integer(Constants.IDEAL_TIME_VALID)
            .min(2, Constants.IDEAL_TIME_MIN_2)
            .max(60, Constants.IDEAL_TIME_MAX_60),
        otherwise: (schema) => schema.optional(),
      }),
      cash_discount_percentage: Yup.number().when("merchant_fee_type", {
        is: "dual_pricing",
        then: (schema) =>
          schema
            .required(Constants.DUAL_CASH_DISCOUNT_REQUIRED)
            .typeError(Constants.DUAL_CASH_DISCOUNT_NOT_VALID)
            .min(0.01, Constants.DUAL_CASH_DISCOUNT_MIN_VALUE)
            .max(100, Constants.DUAL_CASH_DISCOUNT_MAX_VALUE),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: async (values) => {
      setState({ ...state, formData: values });
      myContext.handleLoading(true);
      let payload = {
        ...values,
        account_settings: true,
        location_id: id,
        ideal_time: values.logout_time == false ? "" : values.ideal_time,
        cash_discount_percentage:
          values.merchant_fee_type == "dual_pricing"
            ? values.cash_discount_percentage
            : 0,
        extra_item_threshold: (undefined != values.extra_item_threshold && null != values.extra_item_threshold && '' != values.extra_item_threshold) ? values.extra_item_threshold :"",
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err?.response?.data;
          error({ actionData });
        });
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onClose) {
      props.onClose();
      // props.current=true
    }
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        > */}
        {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Account Settings"]}
                breadCrumTitle={"Account Settings"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  !formik.isValid || buttonDisable
                    ? // (formik.values.logout_time == true &&
                      //   (formik.values.ideal_time == "" ||
                      //     null == formik.values.ideal_time ||
                      //     2 > formik.values.ideal_time))
                      true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={5} />
        {/* <CardBody> */}
        <Stack divider={<StackDivider />} spacing="2">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "100%", md: "35%" }}
                ml={{ base: "0", md: "1.9rem" }}
                mt={10}
              >
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  Operations
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Customize operations and payment settings details
                </Text>
              </Box>
              <Box mr={"0.9rem"} w={{ base: "100%", md: "65%" }}>
              <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
              <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Clockout Users @ Midnight"}
                  mt="0.31rem"
                />
                </Flex>
                  <PosSwitchButton
                    id="clockout"
                    switchValue={formik.values.clockout || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  If you enabled, then all logged in users will be clocked out at midnight.
                </Text>
                {undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE && 
                <Stack direction={"column"}>
                  <Box>
                    <PosLable
                      requiredLabel={true}
                      name={"Merchant Fee Type"}
                      fontWeight={"500"}
                    />
                    <SelectButton
                      id="merchant_fee_type"
                      value={formik.values.merchant_fee_type}
                      onChange={(e) => {
                        // onChangeScheduleType(e);
                        formik.setFieldTouched("cash_discount_percentage",true)
                        formik.setFieldValue(
                          "cash_discount_percentage",""
                        );
                        formik.setFieldValue(
                          "merchant_fee_type",e.value
                        );
                      }}
                      options={scheduledOptions}
                    />
                  </Box>
                  {formik.values.merchant_fee_type == "dual_pricing" && (
                    <Box>
                      <PosLable
                        requiredLabel={true}
                        name={"Cash Discount Percentage"}
                        fontWeight={"500"}
                      />
                      <PosInput
                        id="cash_discount_percentage"
                        placeholder={"Enter Cash Discount Percentage"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.cash_discount_percentage || ""}
                        // onKeyDown={handleKeyDown}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "cash_discount_percentage",
                            twofix(formik.values.cash_discount_percentage)
                          );
                        }}
                        posInput={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.cash_discount_percentage &&
                          formik.errors.cash_discount_percentage
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {
                        formik.errors.cash_discount_percentage ? (
                          <span>{formik.errors.cash_discount_percentage}</span>
                        ) : null}
                      </Text>
                    </Box>
                  )}
                </Stack>}
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Logout POS User after every Order"}
                  mt="0.31rem"
                />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                  <PosSwitchButton
                    id="logout_order"
                    switchValue={formik.values.logout_order || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                    mt={"0.30rem"}
                    h={"1.44rem"}
                    w={{base:"15rem",md:"40%"}}
                    color={Colors.taxCreateExemptEbt}
                    style={{
                      flexShrink: "0",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.18px",
                    }}
                  >
                    If you enabled, then after each order employee will be clocked out.
                  </Text>
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Automatic Logout Idle POS User"}
                  mt="0.31rem"
                />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                  <PosSwitchButton
                    id="logout_time"
                    switchValue={formik.values.logout_time || ""}
                    onChange={(e) => {
                      formik.setFieldValue("ideal_time", "");
                      formik.setFieldValue("logout_time", e.target.checked);
                    }}
                    mt={"2rem"}
                    ml={"1.5rem"}
                    // onChange={formik.handleChange}
                  />
                  </Flex>
                  <Text
                    mt={"0.30rem"}
                    h={"1.44rem"}
                    w={{base:"15rem",md:"40%"}}
                    color={Colors.taxCreateExemptEbt}
                    style={{
                      flexShrink: "0",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.18px",
                    }}
                  >
                    If enabled, users will be automatically clocked out after the specified idle time if they are logged in but inactive.
                  </Text>
                {/* </Stack> */}
                {formik.values.logout_time == true ? (
                  <Box>
                    <PosLable
                      name={"Idle Time (Minutes)"}
                      label={true}
                      fontWeight={500}
                    ></PosLable>
                    <PosInput
                      posNumberInput={true}
                      id={"ideal_time"}
                      inputValue={formik.values.ideal_time || ""}
                      handleBlur={formik.handleBlur}
                      handleInputChange={formik.handleChange}
                    ></PosInput>
                    <Text color={Colors.errorColor}>
                      { formik.errors.ideal_time ? (
                        <span>{formik.errors.ideal_time}</span>
                      ) : null}
                    </Text>
                  </Box>
                ) : null}
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Send Server Update Notification to POS"}
                  mt="0.31rem"
                />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                  <PosSwitchButton
                    id="latest_update_notify"
                    switchValue={formik.values.latest_update_notify || ""}
                    onChange={formik.handleChange}
                    disabled={formik.values.auto_sync}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                {/* </Stack> */}
                  <Text
                    w={{base:"15rem",md:"40%"}}
                    mt={"0.30rem"}
                    h={"1.44rem"}
                    color={Colors.taxCreateExemptEbt}
                    style={{
                      flexShrink: "0",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.18px",
                    }}
                  >
                    If enabled, it will let you know in the Point of Sale if any product is changed on the server but not yet synced.
                  </Text>
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                  <PosLable
                    label={true}
                    fontWeight={500}
                    name={"Auto Sync functionality in POS"}
                    mt="0.31rem"
                  />
                  <Tippy
                    content="To use this functionality make sure 'Send Server Update Notification to POS' is set to 'Yes'"
                    interactive={true}
                    maxWidth={390}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt="2rem"
                      ml="0.3rem"
                    />
                  </Tippy>
                  </Flex>
                  <PosSwitchButton
                    disabled={!formik.values.latest_update_notify}
                    id="auto_sync"
                    switchValue={formik.values.auto_sync || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                {/* </Stack> */}
                  
                <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  If enabled, Point of Sale will automatically pull the updated from the server.
                </Text>
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Favorites Functionality in POS"}
                  mt="0.31rem"
                />
                </Flex>
                  <PosSwitchButton
                    id="favorites"
                    switchValue={formik.values.favorites || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                  mb={5} 
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  If enabled, you can set your custom screen layout in the Point of Sale.
                </Text>
                {/* </Stack> */}
              </Box>
            </Flex>
          </Box>
          <Box mr={"0.9rem"}>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "100%", md: "35%" }}
                ml={{ base: "0", md: "1.5rem" }}
                mt={10}
              >
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  Payments
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Customize operations and payment settings details
                </Text>
              </Box>
              <Box w={{ base: "100%", md: "65%" }}>
              <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
              <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Display EBT Button on POS"}
                  mt="0.31rem"
                />
                </Flex>
                  <PosSwitchButton
                    id="ebt_enable"
                    switchValue={formik.values.ebt_enable || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  This will allow you the accept the EBT as a form of payment. 
                </Text>
                {/* </Stack> */}
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Display Other(payment) button on POS"}
                  mt="0.31rem"
                />
                </Flex>
                  <PosSwitchButton
                    id="other_type"
                    switchValue={formik.values.other_type || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  With this option, you can opt to record other forms of payments taken elsewhere such as zelle, check etc.
                </Text>
                {/* </Stack> */}
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Credit Account Functionality in POS"}
                  mt="0.31rem"
                />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                  <PosSwitchButton
                    id="credit_account"
                    switchValue={formik.values.credit_account || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  If enabled, you can create invoice for wholesale customers. 
                </Text>
                {/* </Stack> */}
                <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
                <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Gift Card Functionality in POS"}
                  mt="0.31rem"
                />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                > */}
                  <PosSwitchButton
                    id="gift_card"
                    switchValue={formik.values.gift_card || ""}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                  </Flex>
                  <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  If enabled, you can sell and accept giftcard as a payment. 
                </Text>
                {/* </Stack> */}
                <PosLable
                  name={"Extra Item Permission Threshold"}
                  label={true}
                  fontWeight={500}
                ></PosLable>
                <PosInput
                  id="extra_item_threshold"
                  placeholder={"Extra Item Permission Threshold"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.extra_item_threshold || ""}
                  // handleBlur={formik.handleBlur}
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                    formik.setFieldValue(
                      "extra_item_threshold",
                      twofix(formik.values.extra_item_threshold)
                    );
                    // formik.handleBlur(e);
                  }}
                  posNumberInput={true}
                  inputType={"text"}
                  inputError={
                    formik.touched.extra_item_threshold &&
                    formik.errors.extra_item_threshold
                  }
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.extra_item_threshold &&
                  formik.errors.extra_item_threshold ? (
                    <span>{formik.errors.extra_item_threshold}</span>
                  ) : null}
                </Text>
                <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  // w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                 This threashold sets the limit if employee can ring up Extra Item above certain threashold.  
                </Text>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Auto Select Taxes in Extra Items"}
                  mt="0.31rem"
                />
                <Box className="card flex justify-content-center">
                  {/* <MultiSelect
                    value={formik?.values?.selectedTaxes}
                    onChange={(e) =>
                      formik.setFieldValue("selectedTaxes", e.value)
                    }
                    options={taxes}
                    optionLabel="name"
                    filter
                    placeholder="Select Taxes"
                    style={{ width: "100%" }}
                    className="w-full md:w-20rem"
                  /> */}
                  <PosDropDown
                    options={taxes}
                    width={"100%"}
                    lableAvailable={true}
                    value={formik?.values?.selectedTaxes}
                    onChange={(e) =>
                      formik.setFieldValue("selectedTaxes", e.value)
                    }
                    filter
                    multiSelect={true}
                    optionLabel="name"
                    placeholder="Select Taxes"
                    className="w-full md:w-20rem"
                    maxSelectedLabels={2}
                  />
                  <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  // w={{base:"15rem",md:"40%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                 You can set the taxes you want automatically selected, when item is rung up on the Point of Sale using Add Extra Item functionality.
                </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Stack>
        {/* <Flex
          minWidth="max-content"
          gap="2"
          mt={{ base: "4.72rem", md: "4.72rem" }}
        >
          <Spacer />
          <ButtonGroup>
            <PosFormButton
              onClick={() => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                !formik.isValid || buttonDisable
                  ?
                    true
                  : false
              }
              buttonsubmit={"Save Changes"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </ButtonGroup>
        </Flex> */}
        {/* </CardBody>
        </Card> */}
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationAccountSettings);
