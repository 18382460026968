// Filename - components/SidebarData.js

import React from "react";
import * as RiIcons from "react-icons/ri";
import marketing from "../../assets/images/Vector (1).svg";
import purchasing from "../../assets/images/Vector (2).svg";
import dashboard from "../../assets/images/Vector (3).svg";
import customer from "../../assets/images/Vector.svg";
import customerActive from "../../assets/images/customers.svg";
import dashboardActive from "../../assets/images/dashboard.svg";
import establishmentActive from "../../assets/images/esta.svg";
import integration from "../../assets/images/fa6-solid_link.svg";
import hr from "../../assets/images/ic_baseline-work.svg";
import hrActive from "../../assets/images/ic_baseline-work_active.svg";
import marketingActive from "../../assets/images/marketing.svg";
import establishment from "../../assets/images/material-symbols-light_home-work-rounded.svg";
import productActive from "../../assets/images/mdi_tag.svg";
import product from "../../assets/images/products.svg";
import reportActive from "../../assets/images/reports.svg";
import rewardActive from "../../assets/images/rewards.svg";
import reward from "../../assets/images/teenyicons_gift-solid.svg";
import report from "../../assets/images/uil_analytics.svg";
import PerchaseOrder from "../../assets/images/Purchase_Order.svg";
import * as Constants from "../../constants/Constants";
import settings from "../../assets/images/settings.svg";
import setting from "../../assets/images/setting.svg";

//TODO Constant
export const SidebarData = [
  {
    title: Constants.DASHBOARD_TITLE,
    path: Constants.DASHBOARD_PATH,
    icon: dashboard,
    activeIcon: dashboardActive,
  },
  {
    title: Constants.HR_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: hr,
    activeIcon: hrActive,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.HR_ROLE_TITLE,
        path: Constants.HR_ROLE_PATH,
        CrudPath: [
          Constants.HR_ROLE_PATH,
          Constants.ROLE_CREATE,
          Constants.ROLE_EDIT,
        ],
      },
      {
        title: Constants.HR_EMPLOYEE_TITLE,
        path: Constants.HR_EMPLOYEE_PATH,
        CrudPath: [
          Constants.HR_EMPLOYEE_PATH,
          Constants.EMPLOYEES_VIEW,
          Constants.EMPLOYEES_EDIT,
          Constants.EMPLOYEES_CREATE,
        ],
      },
    ],
  },
  // {
  //   title: Constants.ESTABLISHMENT_TITLE,
  //   path: Constants.SIGNIN_PATH,
  //   icon: establishment,
  //   activeIcon: establishmentActive,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: Constants.ESTABLISHMENT_LOCATION_TITLE,
  //       path: Constants.ESTABLISHMENT_LOCATION_PATH,
  //       CrudPath: [
  //         Constants.ESTABLISHMENT_LOCATION_PATH,
  //         Constants.LOCATION_CREATE,
  //         Constants.LOCATION_EDIT,
  //         Constants.LOCATION_SETTING_OPTIONS_PAGE,
  //       ],
  //     },
  //     {
  //       title: Constants.ESTABLISHMENT_POS_STATION_TITLE,
  //       path: Constants.POS_LIST,
  //       CrudPath: [
  //         Constants.POS_LIST,
  //         Constants.POS_CREATE,
  //         Constants.POS_EDIT,
  //         Constants.POS_SETTING_DUPLICATE,
  //         Constants.POS_EDIT_SETTING,
  //         Constants.POS_VIEW_SETTING,
  //       ],
  //     },
  //   ],
  // },
  {
    title: Constants.PRODUCT_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: product,
    activeIcon: productActive,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.PRODUCT_LIST_TITLE,
        path: Constants.PRODUCT_LIST_PATH,
        CrudPath: [
          Constants.PRODUCT_LIST_PATH,
          Constants.PRODUCT_CREATE,
          Constants.PRODUCT_EDIT,
          Constants.PRODUCT_IMPORT_EXPORT_PATH,
        ],
        // cName: "sub-nav",
      },
      {
        title: Constants.PRICE_BOOK_LIST_TITLE,
        path: Constants.PRICE_BOOK_LIST_PATH,
        CrudPath: [
          Constants.PRICE_BOOK_CREATE,
          Constants.PRICE_BOOK_LIST_PATH,
          Constants.PRICE_BOOK_EDIT
        ],
        // cName: "sub-nav",
      },
      {
        title: Constants.PRODUCT_CATEGORY_TITLE,
        path: Constants.CATEGORY_LIST,
        CrudPath: [
          Constants.CATEGORY_LIST,
          Constants.CATEGORY_CREATE,
          Constants.CATEGORY_EDIT,
        ],
        // cName: "sub-nav",
      },
      {
        title: Constants.PRODUCT_MODIFIER_TITLE,
        path: Constants.PRODUCT_MODIFIER_PATH,
        CrudPath: [
          Constants.PRODUCT_MODIFIER_PATH,
          Constants.MODIFIER_SET_CREATE,
          Constants.MODIFIER_SET_EDIT,
        ],
      },
      {
        title: Constants.PRODUCT_TAX_TITLE,
        path: Constants.TAX_LIST,
        CrudPath: [
          Constants.TAX_LIST,
          Constants.TAX_CREATE,
          Constants.TAX_EDIT,
        ],
        // cName: "sub-nav",
      },
      {
        title: Constants.PRODUCT_PROMOTION_TITLE,
        path: Constants.PRODUCT_PROMOTION_PATH,
        CrudPath: [
          Constants.PRODUCT_PROMOTION_PATH,
          Constants.PROMOTION_CREATE,
          Constants.PROMOTION_EDIT,
        ],
        // cName: "sub-nav",
      },
      {
        title: Constants.PRODUCT_TARE_TITLE,
        path: Constants.PRODUCT_TARE_PATH,
        CrudPath: [
          Constants.PRODUCT_TARE_PATH,
          Constants.TARE_CONTAINER_CREATE,
          Constants.TARE_CONTAINER_EDIT,
        ],
      },
    ],
  },
  {
    title: Constants.REPORT_TITLE,
    path: Constants.REPORT_PATH,
     CrudPath: [
      Constants.TOTAL_DAILY_SALE,
      Constants.TOTAL_SALE_BY_DAY,
      Constants.TOTAL_CATEGORY_SALE,
      Constants.TOTAL_SALE_BY_POS_STATION,
      Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_ROUTE,
      Constants.TAXES_REPORT_ROUTE,
      Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_ROUTE,
      Constants.SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE,
      Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE,
      Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
      Constants.CUSTOMER_CREDIT_ACCOUNT_ROUTE,
      Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
      Constants.ORDER_LIST_PATH,
      Constants.CASHBACK_REPORT_ROUTE,
      Constants.REFUND_WITH_ORDER_ROUTE,
      Constants.EXTRA_ITEM_ROUTE,
      Constants.VARIABLE_ITEM_ROUTE,
      Constants.TILL_CREATE,
      Constants.TILL_VIEW,
      Constants.TILL_EDIT,
      Constants.TILL_REPORT_ROUTE,
      Constants.PAYOUT_EDIT_ROUTE,
      Constants.PAYOUT_CREATE,
      Constants.PAY_OUT_REPORT_ROUTE,
      Constants.PAY_IN_REPORT_ROUTE,
      Constants.PAY_IN_CREATE_ROUTE,
      Constants.PAY_IN_EDIT_ROUTE,
      Constants.CUSTOMER_CREDIT_LEDGER_ROUTE,
      Constants.CUSTOMER_UNUSED_CREDIT_ROUTE,
      Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE,
      Constants.PRODUCT_NOT_FOUND_BARCODE,
      Constants.BOTTLE_DEPOSIT_REFUND,
      Constants.REFUND_WITHOUT_ORDER,
      Constants.ONLINE_GIFT_CARD_SALE,
      Constants.OUTSTATING_GIFT_CARD_LIABILITY,
      Constants.DISCOUNT_REPORT_ROUTE,
      Constants.RECOUNT_REPORT_ROUTE,
      Constants.VOID_REPORT_ROUTE,
      Constants.SHRINKAGE_REPORT_ROUTE,
      Constants.CUSTOMER_AGED_RECEIVABLE_ROUTE,
      Constants.CUSTOMER_STATEMENT_ROUTE,
    ],
    icon: report,
    activeIcon: reportActive,
  },
  {
    title: Constants.PURCHASING_TITLE,
    path: Constants.SIGNIN_PATH,   
    icon: purchasing,
    activeIcon: PerchaseOrder,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.PURCHASING_VENDOR_TITLE,
        path: Constants.VENDOR_LIST,
        CrudPath: [
          Constants.VENDOR_LIST,
          Constants.VENDOR_CREATE,
          Constants.VENDOR_EDIT,
        ],
      },
      {
        title: Constants.PURCHASING_ORDER_TITLE,
        path: Constants.PURCHASING_ORDER_PATH,
        CrudPath: [
            Constants.PURCHASING_ORDER_PATH,
            Constants.PURCHASING_ORDER_CREATE,
            Constants.PURCHASING_ORDER_EDIT,
        ],
      },
      {
        title: Constants.TRANSFER_ORDER_TITLE,
        path: Constants.TRANSFER_ORDER_PATH,
        CrudPath: [
            Constants.TRANSFER_ORDER_PATH,
            Constants.TRANSFER_ORDER_CREATE,
            Constants.TRANSFER_ORDER_EDIT,
        ],
      },
    ],
  },
  {
    title: Constants.MARKETING_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: marketing,
    activeIcon: marketingActive,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.MARKETING_DASHBOARD_TITLE,
        path: Constants.MARKETING_DASHBOARD_PATH,
        CrudPath: [Constants.MARKETING_DASHBOARD_PATH],
      },
      {
        title: Constants.MARKETING_COUPONS_TITLE,
        path: Constants.COUPON_LIST,
        CrudPath: [
          Constants.COUPON_LIST,
          Constants.COUPON_CREATE,
          Constants.COUPON_EDIT,
          Constants.COUPON_VIEW,
        ],
      },
      {
        title: Constants.MARKETING_SEGMENT_TITLE,
        path: Constants.MARKETING_SEGMENT_PATH,
        CrudPath: [
          Constants.SEGMENT_LIST,
          Constants.SEGMENT_CREATE,
          Constants.SEGMENT_EDIT,
          Constants.SEGMENT_VIEW,
        ],
      },
      {
        title: Constants.MARKETING_VERIFY_EMAIL_TITLE,
        path: Constants.MARKETING_VERIFY_EMAIL_PATH,
        CrudPath: [Constants.MARKETING_VERIFY_EMAIL_PATH],
      },
      {
        title: Constants.MARKETING_CAMPAIGN_TITLE,
        path: Constants.MARKETING_CAMPAIGN_PATH,
        CrudPath: [
          Constants.MARKETING_CAMPAIGN_PATH,
          Constants.CAMPAIGN_CREATE,
          Constants.CAMPAIGN_EDIT,
        ],
      },
    ],
  },
  {
    title: Constants.REWARDS_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: reward,
    activeIcon: rewardActive,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.REWARDS_CARD_TITLE,
        path: Constants.REWARDS_CARD_PATH,
        CrudPath: [
          Constants.REWARDS_CARD_PATH,
          Constants.REWARD_CARDS_VIEW,
          Constants.REWARD_CARDS_CREATE,
          Constants.REWARD_CARDS_EDIT,
        ],
      },
      {
        title: Constants.REWARDS_LIST_TITLE,
        path: Constants.REWARDS_LIST_PATH,
        CrudPath: [
          Constants.REWARDS_LIST_PATH,
          Constants.REWARD_CREATE,
          Constants.REWARD_EDIT,
        ],
      },
      {
        title: Constants.REWARDS_GIFT_CARD_TITLE,
        path: Constants.REWARDS_GIFT_CARD_PATH,
        CrudPath: [Constants.REWARDS_GIFT_CARD_PATH, Constants.GIFT_CARD_VIEW],
      },
    ],
  },
  // {
  //   title: Constants.CUSTOMERS_TITLE,
  //   path: Constants.CUSTOMERS_PATH,
  //   icon: customer,
  //   activeIcon: customerActive,
  //   CrudPath: [
  //     Constants.CUSTOMERS_PATH,
  //     Constants.CUSTOMER_VIEW_PATH,
  //     Constants.CUSTOMERS_CREATE,
  //     Constants.CUSTOMERS_EDIT,
  //     Constants.CUSTOMER_VIEW_PATH,
  //   ],
  // },
  {
    title: Constants.SETTING_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: setting,
    activeIcon: settings,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.ESTABLISHMENT_LOCATION_TITLE,
        path: Constants.ESTABLISHMENT_LOCATION_PATH,
        CrudPath: [
          Constants.ESTABLISHMENT_LOCATION_PATH,
          Constants.LOCATION_CREATE,
          Constants.LOCATION_EDIT,
          Constants.LOCATION_SETTING_OPTIONS_PAGE,
        ],
      },
      {
        title: Constants.ESTABLISHMENT_POS_STATION_TITLE,
        path: Constants.POS_LIST,
        CrudPath: [
          Constants.POS_LIST,
          Constants.POS_CREATE,
          Constants.POS_EDIT,
          Constants.POS_SETTING_DUPLICATE,
          Constants.POS_EDIT_SETTING,
          Constants.POS_VIEW_SETTING,
        ],
      },
      {
        title: Constants.GLOBAL_TITLE,
        path: Constants.SYS_ALL_GLOBAL_SETTINGS,
        CrudPath: [
          Constants.UNIT_OF_MEASURE_CREATE,
          Constants.SYS_ALL_GLOBAL_SETTINGS,
          Constants.SYS_ALL_LOC_GLOBAL_SETTING
        ],
      },
    ],
  },
  {
    title: Constants.INTEGRATION_TITLE,
    path: Constants.SIGNIN_PATH,
    icon: integration,
    activeIcon: integration,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: Constants.INTEGRATION_LIST_TITLE,
        path: Constants.INTEGRATION_LIST_PATH,
        CrudPath: [Constants.INTEGRATION_LIST_PATH],
      },
      {
        title: Constants.INTEGRATION_WEBHOOK_TITLE,
        path: Constants.INTEGRATION_WEBHOOK_PATH,
        CrudPath: [Constants.INTEGRATION_WEBHOOK_PATH],
      },
      {
        title: Constants.INTEGRATION_API_TITLE,
        path: Constants.INTEGRATION_API_PATH,
        CrudPath: [Constants.INTEGRATION_API_PATH],
      },
      {
        title: Constants.KDS_API_TITLE,
        path: Constants.KDS_API_PATH,
        CrudPath: [Constants.KDS_API_PATH, Constants.KDS_CREATE],
      },
      {
        title: Constants.CDS_API_TITLE,
        path: Constants.CDS_API_PATH,
        CrudPath: [Constants.CDS_API_PATH, Constants.CDS_CREATE],
      },
    ],
  },
];
