import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import {
  commonDateFormate,onlyDateFormate
} from "../../../helpers/utils/Utils";
import { GridToolbar } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SpeedIcon from "@mui/icons-material/Speed";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDialogAlert,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllCampaigns, getDeactiveCampaigns } from "./CampaignService";
import CampaignView from "./CampaignView";

const CampaignList = (props) => {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH);
  const stateData = useLocation();
  const effectRun = useRef(true);
  const tabNames = ["Active", "Inactive"];
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const { addToast } = PosTostMessage();

  const [curruntTab, setCurruntTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [deactiveCampaignData, setDeactiveCampaignData] = useState([]);
  const [totalDeactivatecount, setDeactiveTotalCount] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [smsNumberExist, setSmsNumberExist] = useState(0);
  const cancelRef = React.useRef();
  // const [searchQuery, setSearchQuery] = useState(
  //   undefined !== stateData.state &&
  //     null !== stateData.state &&
  //     "" !== stateData.state
  //     ? stateData.state.filter
  //     : ""
  // );
  const [buttonDisable, setButtonDisable] = useState(false);

  const [activeColumnVisibilityModel, setActiveColumnVisibilityModel] =
    React.useState({});

  const [deactiveColumnVisibilityModel, setDeactiveColumnVisibilityModel] =
    React.useState({});
  const btnRef = React.useRef();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const [id, setId] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    page: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:  undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const [sortModel, setSortModel] = useState([
    {
      field: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);

  const {
    isOpen: isSmsNumberNotFoundAlertOpen,
    onOpen: onSmsNumberNotFoundAlertOpen,
    onClose: onSmsNumberNotFoundAlertClose,
  } = useDisclosure();

  const handleTabChange = (index) => {
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setPaginationModelDeactive({
      page: 0,
      pageSize:25,
    });
    setSortModelDeactive([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize:25,
    });
    setSortModel([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    effectRun.current = true;
    if(index == 0){
      deactiveSearchHideRef.current = false;
    }else{
      searchInputHideRef.current = false;
    }
  };

  const handleSearchList = () => {
    props.navigate(Constants.MARKETING_CAMPAIGN_PATH)
    paginationModel.page = 0;
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveCampaignData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.MARKETING_CAMPAIGN_PATH)
  }

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getAllCampaigns(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (
              null != response.data.data.twilio_phone_number &&
              "" != response.data.data.twilio_phone_number
            ) {
              setSmsNumberExist(1);
            }
            setCampaignData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCampaignData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setCampaignData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCampaignData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActiveCampaignData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
      sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRef.current[0].field,
      order_type:
      sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getDeactiveCampaigns(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (response.data.data.response_get_from_ms == 1) {
              // redirect to billing list page if card details not found.
              if (response.data.data.card_details.length > 0) {
                setDeactiveCampaignData(response.data.data.data);
                setDeactiveTotalCount(response.data.data.totalItems);
                setLoading(false);
                myContext.handleLoading(false);
              } else {
                addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.CAMPAIGN_MODULE,
                  alertDescription: Constants.CARD_DETAILS_GET_ERROR,
                });
                props.navigate(Constants.BILLINGS_VIEW_DETAILS);
              }
            } else {
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.CAMPAIGN_MODULE,
                alertDescription: Constants.MS_CARD_DETAILS_GET_ERROR,
              });
              setLoading(false);
            }
          } else {
            setDeactiveCampaignData([]);
            setDeactiveTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setDeactiveCampaignData([]);
          setDeactiveTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setDeactiveCampaignData([]);
      setDeactiveTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveCampaignData();
      }
      effectRun.current = false;
    }
  } ,[paginationModel,sortModel,loading,searchQuery,paginationModelDeactive,sortModelDeactive]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.CAMPAIGN_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveCampaignData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.CAMPAIGN_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);

  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const activeListColumnNames = [
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "title",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "end_date",
      headerName: "End Date",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "scheduled_start_time",
      headerName: "Scheduled Start Time",
      sortable: true,
      ...actionColumnXL,
    },
    {
      field: "marketing_type",
      headerName: "Marketing Type",
      sortable: false,
      ...actionColumnLG,
    },
    {
      field: "campaign_type",
      headerName: "Campaign Type",
      sortable: false,
      ...actionColumnLG,
    },
    {
      field: "dashboard",
      headerName: "Dashboard",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      ...actionColumnMD,
      // renderCell: (params) => (
      //     <IconButton
      //         aria-label="view"
      //         // onClick={() => openFailedPaxResponsePopUp(params)}
      //         sx={{ color: Colors.posNavbarLink }}
      //     >
      //         <SpeedIcon />
      //     </IconButton>
      // ),
      renderCell: (params) => {
        const rowData = params.row;
        const handleCampaignDashboard = () => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
            type:rowData.campaign_type,
          }
          props.navigate(`${Constants.CAMPAIGN_DASHBOARD + rowData.id}`,{
            state: data,
          });
        };
        return (
          <IconButton
            aria-label="view"
            onClick={handleCampaignDashboard}
            sx={{ color: Colors.posNavbarLink }}
          >
            <SpeedIcon />
          </IconButton>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          if (smsNumberExist == 1) {
            myContext.handleLoading(true);
            let data = {
              limit: paginationModel.pageSize,
              page: paginationModel.page,
              order:
                sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
              order_type:
                sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
              filter: searchQuery,
            }
            props.navigate(`${Constants.CAMPAIGN_EDIT + item.id}`,{
              state: data,
            });
          } else {
            onSmsNumberNotFoundAlertOpen();
          }
        };
        const handleViewPage = (item) => {
          myContext.handleLoading(true);
          setId(item.id);
          onViewOpen();
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if(modifiedCouponData.length == 1 && paginationModel.page != 0){
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.MARKETING_CAMPAIGN_PATH,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "View",
              icon: (
                <RemoveRedEyeOutlinedIcon
                  style={{ color: Colors.posViewTextColor }}
                />
              ),
              onClick: (item) => handleViewPage(item),
            },
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const modifiedCouponData = campaignData.map((data) => ({
    id: data.id,
    title: data.title,
    start_date: undefined != data.start_date && null != data.start_date && '' != data.start_date ? onlyDateFormate(data.start_date+"Z") : "N.A.",
    end_date: undefined != data.end_date && null != data.end_date && '' != data.end_date ? onlyDateFormate(data.end_date+"Z") : "N.A.",
    scheduled_start_time: commonDateFormate(data.scheduled_start_time+"Z"),
    marketing_type:
      data.is_send_email == 1 && data.is_send_sms == 1
      ? Constants.EMAIL + ' & ' + Constants.SMS
      : data.is_send_email == 1 && data.is_send_sms == 0
      ? Constants.EMAIL
      : data.is_send_email == 0 && data.is_send_sms == 1
      ? Constants.SMS : Constants.EMAIL,
    campaign_type: (null == data.targeted || '' == data.targeted) ? ((data.coupons.length > 0) ? 'Coupon' : Constants.GENERIC) : ((data.targeted == 1) ? 'Coupon' : Constants.GENERIC)
  }));


  const deactiveColumnNames = [
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "title",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "end_date",
      headerName: "End Date",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "scheduled_start_time",
      headerName: "Scheduled Start Time",
      sortable: true,
      ...actionColumnXL,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          myContext.handleLoading(true);
          setButtonDisable(true);
          if (params.row.reactive_flag == 0) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.CAMPAIGN_MODULE,
              alertDescription:
                Constants.CAMPAIGN_REACTIVE_FOR_NEXT_MONTH_WITH_CANCEL_PLAN_ERROR,
            });
            props.navigate(Constants.CAMPAIGN_EDIT + rowData.id);
          } else {
            if (
              modifiedDeactiveCouponData.length == 1 &&
              paginationModelDeactive.page != 0
            ) {
              paginationModelDeactive.page = paginationModelDeactive.page - 1;
            }
            let data = {
              id: rowData.id,
              type: Constants.REACTIVE,
            };
            submit(data, {
              method: Constants.POST,
              path: Constants.MARKETING_CAMPAIGN_PATH,
            });
          }
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const modifiedDeactiveCouponData = deactiveCampaignData.map((data) => ({
    id: data.id,
    title: data.title,
    start_date: undefined != data.start_date && null != data.start_date && '' != data.start_date ? onlyDateFormate(data.start_date+"Z") : "N.A.",
    end_date: undefined != data.end_date && null != data.end_date && '' != data.end_date ? onlyDateFormate(data.end_date+"Z") : "N.A.",
    scheduled_start_time: commonDateFormate(data.scheduled_start_time+"Z"),

    // start_date:
    //   undefined != data.start_date &&
    //   null != data.start_date &&
    //   "" != data.start_date
    //     ? moment(data.start_date).format("MM/DD/YYYY")
    //     : "N.A.",
    // end_date:
    //   undefined != data.end_date && null != data.end_date && "" != data.end_date
    //     ? moment(data.end_date).format("MM/DD/YYYY")
    //     : "N.A.",
    // scheduled_start_time: moment(data.scheduled_start_time).format(
    //   "MM/DD/YYYY HH:mm:ss"
    // ),
    reactive_flag: data.reactive_flag,
  }));

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <CampaignView
        fetchData={fetchData}
        isViewOpen={isViewOpen}
        placement="right"
        onViewClose={onViewClose}
        onViewOpen={onViewOpen}
        finalFocusRef={btnRef}
        id={id}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Campaigns"}
          discription={
            "Manage and create Campaigns for your products on OctoPos, to empower your business"
          }
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                  filter: curruntTab == 1 ? "" : searchQuery,
              }
              props.navigate(Constants.CAMPAIGN_CREATE,{
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={8}
            mb={8}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear} 
            />
          </Flex> */}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Box >
                  <PosDataGridTable
                    columns={activeListColumnNames}
                    rows={modifiedCouponData}
                    totalcount={totalcount}
                    columnVisibilityModel={activeColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setActiveColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    fileName={Constants.ACTIVE_CAMPAIGN_EXPORT_FILENAME}
                  />
                </Box>
              ) : (
                <PosNoDataFound title={Constants.NO_CAMPAIGNS_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalDeactivatecount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={modifiedDeactiveCouponData}
                  totalcount={totalDeactivatecount}
                  columnVisibilityModel={deactiveColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setDeactiveColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DEACTIVE_CAMPAIGN_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_CAMPAIGNS_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onSmsNumberNotFoundAlertClose}
        isOpen={isSmsNumberNotFoundAlertOpen}
        title={"WARNING"}
        discription={
          "Your SMS Number is still in the approval process. It can take anywhere from one week to four weeks to be approved. Once it’s approved, you can start creating and sending campaigns."
        }
        onClick={onSmsNumberNotFoundAlertClose}
        alertDialogHeight={"auto"}
        footerSingleButton={true}
        buttonNames={"Ok"}
      />
    </Box>
  );
};
export default WithRouter(CampaignList);
