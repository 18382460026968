import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  ButtonGroup
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse21.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix,commonDateFormate } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getGiftCardAuditTrailDetails } from "./GiftCardService";

const GiftCardView = (props) => {
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const stateData = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [giftCardData, setGiftCardSetData] = useState([]);
  const [giftCardDatas, setGiftCardSetDatas] = useState([]);
  const [curruntBalence, setCurruntBalence] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [giftCardId, setGiftCardSetId] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [giftCardAuditTrailData, setGiftCardAuditTrailData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const effectRun = useRef(true);
  const { id } = useParams();
  const { addToast } = PosTostMessage();
  const [giftCardDataBalance, setGiftCardDataBalance] = useState([]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setGiftCardSetData(loaderResponse.data.data.giftCardData);
          setGiftCardSetDatas(loaderResponse.data.data.giftCardData.user);
          setGiftCardSetId(loaderResponse.data.data.giftCardData.id);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.GIFT_CARD_VIEW + id);
        fetchData(id);
        setGiftCardDataBalance(actionResponse.data.data.gift_card_balance);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.GIFT_CARD_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const closepop = () => {
    onClose();
    setCurruntBalence(null);
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
  };
  const [addedValue, setAddedValue] = useState(null); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await formik.handleSubmit();
        if (stateData?.state?.screen === "Outstanding") {
          let payload = {
            screen: "Outstanding",
            limit: stateData?.state?.limit,
            page: stateData?.state?.page,
            order: stateData?.state?.order,
            order_type: stateData?.state?.order_type,
          };
          setAddedValue(payload); 
        }
        onClose();
        setLoading(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleaddPage = (id) => {
    myContext.handleLoading(true);
    setCurruntBalence(giftCardData.balance);
    setGiftCardSetId(id);
    onOpen();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    myContext.handleLoading(false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("external_reference", false);
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };
  const fetchData = async (id) => {
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      id: parseInt(id),
    };
    try {
      getGiftCardAuditTrailDetails(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setGiftCardAuditTrailData(
              response.data.data.giftCardAuditTrailData
            );
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setGiftCardAuditTrailData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setGiftCardAuditTrailData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setGiftCardAuditTrailData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      fetchData(id);
      // return () => {
      effectRun.current = false;
      // };
    }
    if (loaderResponse && loaderResponse.data && loaderResponse.data.data) {
      const newBalance = loaderResponse.data.data.giftCardData.balance;
      setGiftCardDataBalance((prevState) => ({
        ...prevState,
        balance: newBalance,
      }));
    }
  }, [
    sortType,
    searchQuery,
    rowsPerPage,
    page,
    loading,
    sortColumn,
    loaderResponse,
  ]);
  const columnNames = [
    {
      columnNames: "Sr No.",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Date(UTC)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Description",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Balance($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Order Number",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Location",
      sortName: "",
      width: "",
    },
    {
      columnNames: "User",
      sortName: "",
      width: "",
    },
  ];
  function reportDateFormat(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    
    const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }
  const modifiedData =
    giftCardAuditTrailData?.map((Giftcard) => {
      // const date = new Date(Giftcard.date);
      // const formattedDate = `${String(date.getDate()).padStart(
      //   2,
      //   "0"
      // )}/${String(date.getMonth() + 1).padStart(
      //   2,
      //   "0"
      // )}/${date.getFullYear()} ${String(date.getHours()).padStart(
      //   2,
      //   "0"
      // )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
      //   date.getSeconds()
      // ).padStart(2, "0")}`;

      return {
        id:Giftcard.sr_no,
        // sr_no: Giftcard.sr_no,
        // date: formattedDate,
        date: reportDateFormat(Giftcard.date),
        description: Giftcard.description,
        amount: Giftcard.amount,
        type: Giftcard.type,
        balance: Giftcard.balance,
        order_number: Giftcard.order_number || "N.A",
        // location: Giftcard.location || "N.A",
        location:
          Giftcard.location === "EXTRA ITEM LOCATION"
            ? "ONLINE"
            : Giftcard.location || "N.A",
        user: Giftcard.user,
      };
    }) || [];

  const formik = useFormik({
    initialValues: {
      id: id,
      amount: "",
      external_reference: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
      .typeError('The amount must be a integer')
        .required(Constants.GIFT_CARD_PLEASE_ENTER_A_AMOUNT)
        .max(500, Constants.GIFT_CARD_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.GIFT_CARD_CREDIT_LIMIT_MIN_ERROR),
      external_reference: Yup.string().max(
        191,
        Constants.GIFT_CARD_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
      ),
    }),
    onSubmit: async (values) => {
      try {
        myContext.handleLoading(true);
        const payload = {
          ...values,
        };
        const { amount, external_reference } = payload;

        let data = {
          id: id,
          amount: amount,
          reason:external_reference,
          external_reference: external_reference,
          type: Constants.ADDCREDIT,
        };

        submit(data, {
          method: Constants.POST,
          path: Constants.GIFT_CARD_ADD_CREDIT_API_ROUTE,
        });
        myContext.handleLoading(false);
      } catch (error) {
        myContext.handleLoading(false);
      }
    },
  });
  return (
    <Box bg={Colors.loginAuthBackground} flex="1">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: 4, md: "3.06rem" }}
        pb={{ base: 4, md: "2rem" }}
        pl={{ base: 4, lg: "1.88rem" }}
        pr={{ base: 4, md: "2.75rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              stateData?.state?.screen == "Outstanding"
                ? props.navigate(Constants.GIFT_CARD_LIST)
                : props.navigate(Constants.GIFT_CARD_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Gift Cards", "View Gift Card"]}
            // breadCrumTitle={"#1"}
          />
        </Box>
        <Spacer />
        {/* <PosFormButton
          // isDisabled={
          //   formik.values.amount.length <= 0 ||
          //   formik.errors.amount ||
          //   buttonDisable
          //     ? true
          //     : false
          // }
          buttonsubmit={"Done"}
          w={"6.75rem"}
          display={"flex"}
          width={"6.75rem"}
          height={"2.81rem"}
          padding={"0.63rem"}
          justify-content={"center"}
          align-items={"center"}
          gap={"0.31rem"}
          flex-shrink={"0"}
          // SubmitButton={true}
          onClick={() => {
            props.navigate(Constants.GIFT_CARD_LIST);
          }}
        /> */}
      </Flex>
      <Box
        mt={{ base: 4, md: "0.63rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "start" }}
          pt={{ base: "1.5rem", md: "2.13rem" }}
          pb={{ base: "1.5rem", md: "0.88rem" }}
          pl={{ base: "1rem", lg: "2.06rem" }}
          pr={{ base: "1rem", md: "1.75rem" }}
          gap={2}
        >
          <Box>
            <Text
              fontSize="1.13rem"
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              Gift Card Details
            </Text>

            <Text
              color={Colors.taxCreateExemptEbt}
              style={{
                fontSize: "0.94rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Your personal details for your OctoPos Reward Customer
            </Text>
          </Box>
          <Spacer />
          <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            // onClick={() => {
            //   stateData?.state?.screen == "Outstanding"
            //     ? props.navigate(Constants.OUTSTATING_GIFT_CARD_LIABILITY, {
            //         state: stateData.state,
            //       }) 
            //     : props.navigate(Constants.GIFT_CARD_LIST, {
            //       state: stateData.state,
            //     });
            // }}
            onClick={() => {
              if (stateData?.state?.screen === "Outstanding") {
                props.navigate(Constants.OUTSTATING_GIFT_CARD_LIABILITY, {
                  state: stateData.state,
                });
              } else if (addedValue?.screen === "Outstanding") {
                props.navigate(Constants.OUTSTATING_GIFT_CARD_LIABILITY, {
                  state: addedValue,
                });
              } else {
                props.navigate(Constants.GIFT_CARD_LIST, {
                  state: stateData.state,
                });
              }
            }}
          />
          <PosFormButton
            buttonsubmit={"Add Gift Balance"}
            bg={Colors.posNavbarLink}
            width={!isScreenSize480 ? "11.81rem" : "2.38rem"}
            style={{
              display: "flex",
              width: "5.56rem",
              padding: "0.63rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.63rem",
              borderRadius: "0.31rem",
              border: "1px solid #E3E3E3",
              boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
            }}
            onClick={() => handleaddPage()}
            // handleClick: (item) => handleaddPage(item)
          />
          </ButtonGroup>
        </Flex>
        <>
          <Modal
            isOpen={isOpen}
            onClose={() => {
              //   formik.setFieldValue("amount", "");
              setUserBalance(0);
              onClose();
            }}
            isCentered
            size="xl"
            width="35.63rem"
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                mt={{ base: "0", md: "1.56rem" }}
                style={{ display: "inline-flex", justifyContent: "center" }}
              >
                Add Gift Card Balance
              </ModalHeader>
              <Divider />
              <ModalBody
                pb={{ base: 4, md: "1.38rem" }}
                pl={{ base: 4, lg: "1.06rem" }}
                pr={{ base: 4, md: "1.75rem" }}
              >
                <Stack mt="0.87rem">
                  {/* <Text  > */}
                  <Flex
                    bg={Colors.modifierTable}
                    style={{
                      display: "flex",
                      padding: "0.63rem",
                      alignItems: "center",
                      gap: "0.63rem",
                      flexShrink: 0,
                      borderRadius: "0.63rem",
                    }}
                  >
                    <Image
                      src={warnignIcon}
                      alt="Warning Icon"
                      boxSize="0.81rem"
                      mr="0.5rem"
                    />
                    <Text>
                      Your Available Gift Card Balance is:
                      <Text as="span" fontWeight="bold">
                {giftCardDataBalance.balance !== null &&
                giftCardDataBalance.balance !== undefined &&
                // CustomerBalanceData.length !== 0 &&
                giftCardDataBalance.balance !== ""
                  ? `$${giftCardDataBalance.balance}`
                  : ""}
              </Text>
                    </Text>
                  </Flex>
                  <Text mt="2rem">* Amount:</Text>
                  <PosInput
                    id={"amount"}
                    placeholder={"Enter an amount"}
                    // handleInputChange={formik.handleChange}
                    handleInputChange={(e) => {
                      let newBalance = 0;
                      if (
                        undefined != e.target.value &&
                        null != e.target.value &&
                        "" != e.target.value
                      ) {
                        newBalance =
                          parseFloat(e.target.value) +
                          parseFloat(giftCardDataBalance.balance);
                        setUserBalance(newBalance);
                        formik.handleChange(e);
                      } else {
                        formik.handleChange(e);
                        setUserBalance(parseFloat(giftCardDataBalance.balance));
                      }
                    }}
                    inputValue={formik.values.amount}
                    // handleBlur={formik.handleBlur}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "amount",
                        twofix(formik.values.amount) || ""
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    inputType={"number"}
                    posNumberInput={true}
                  />
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.amount && formik.errors.amount ? (
                      <span>{formik.errors.amount}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack pt={"0.88rem"}>
                  <Text>Reference/ Reason:</Text>
                  <PosInput
                    id="external_reference"
                    // mt={"1rem"}
                    placeholder={"Enter a reason"}
                    color={Colors.posTextInfo}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.external_reference}
                    handleBlur={formik.handleBlur}
                    posInput={true}
                    inputType={"text"}
                  />
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.external_reference &&
                    formik.errors.external_reference ? (
                      <span>{formik.errors.external_reference}</span>
                    ) : null}
                  </Text>
                  {formik.values.amount > 0 && (
                    <Text style={{ color: "#008000" }}>
                      After this transaction your Gift Card Balance will be $
                      <Text as="span" fontWeight="bold">
                        {twofix(userBalance)}
                      </Text>
                      .
                    </Text>
                  )}
                </Stack>
              </ModalBody>

              <ModalFooter
                flexDirection={"column"}
                alignItems={"end"}
                style={{ paddingInlineEnd: "var(--chakra-space-4" }}
              >
                <Flex mb={"1.69rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    onClick={() => {
                      closepop();
                    }}
                    isDisabled={buttonDisable}
                  />
                  <Text mr={{ base: "0.5rem"}}></Text>
                  <PosFormButton
                    isDisabled={
                      !formik.values.amount ||
                      formik.values.amount?.length <= 0 ||
                      formik.errors.amount ||
                      formik.errors.external_reference ||
                      buttonDisable
                        ? true
                        : false||
                        isSubmitting
                    }
                    buttonsubmit={"Submit"}
                    SubmitButton={true}
                    onClick={handleSubmit}
                  />
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <Divider />
        <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          p={{base:"4",md:"8"}}
        >
          <Box
            flex="1"
            width="100%"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Flex
              mt={1.5}
              height={"4.88rem"}
              p={2.5}
              direction="column"
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <Text
                ml={2.5}
                mt={2.5}
                color={Colors.posTextInfo}
                fontWeight={300}
              >
                Current Balance
              </Text>
              <Text ml={2.5} color={Colors.taxInfo}>
                {giftCardDataBalance.balance !== null &&
                giftCardDataBalance.balance !== undefined &&
                giftCardDataBalance.balance !== ""
                  ? `$${giftCardDataBalance.balance}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={{base:"1rem" ,md:"2.31rem"}}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Date Created(UTC)
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{commonDateFormate(giftCardData.created_at)}</Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Created by
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardDatas.name}</Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Email Address
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.email !== null && giftCardData.email !== ""
                  ? giftCardData.email
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <LocalPhoneIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Phone Number
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.phone !== null && giftCardData.phone !== ""
                  ? giftCardData.phone
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Code
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardData.code}</Text>
            </Flex>
          </Box>

          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Flex
              mt={1.5}
              height={"4.88rem"}
              p={2.5}
              direction="column"
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <Text
                ml={2.5}
                mt={2.5}
                color={Colors.posTextInfo}
                fontWeight={300}
              >
                Initial Amount
              </Text>
              <Spacer />
              <Text ml={2.5} color={Colors.taxInfo}>
                {giftCardData.initial_amount !== null &&
                giftCardData.initial_amount !== undefined &&
                giftCardData.initial_amount !== ""
                  ? `$${giftCardData.initial_amount}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={{base:"1rem" ,md:"2.31rem"}}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Initial Amount
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.initial_amount !== null &&
                giftCardData.initial_amount !== undefined &&
                giftCardData.initial_amount !== ""
                  ? `$${giftCardData.initial_amount}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Current Balance
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardDataBalance.balance !== null &&
                giftCardDataBalance.balance !== undefined &&
                giftCardDataBalance.balance !== ""
                  ? `$${giftCardDataBalance.balance}`
                  : ""}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Payment Type
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.payment_types}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Payment External Reference
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {giftCardData.payment_external_reference !== null &&
                giftCardData.payment_external_reference !== ""
                  ? giftCardData.payment_external_reference
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <LocalPhoneIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Type
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{giftCardData.type}</Text>
              {/* <Text color={Colors.posTextInfo}>{giftCardData.phone}</Text> */}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box
        mt={{ base: 4, md: "0.63rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "satrt" }}
          pt={{ base: "1.5rem", md: "3.25rem" }}
          pb={{ base: "1.5rem", md: "2rem" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.56rem" }}
          gap={2}
        >
          <Box>
            <Text
              fontSize="1.13rem"
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              Gift Card Audit Trails
            </Text>
          </Box>
        </Flex>
        <Box mb={12}>
          {loading ? (
            <PosProgress />
          ) : modifiedData.length > 0 ? (
            <PosTable
              columnNames={columnNames}
              rawdata={modifiedData}
              noAction={true}
              totalcount={totalcount}
              // spliteButton={true}
              // spliteOptions={options}
              // DectivatedId={handleDeactive}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              // handleSortColumn={handleSortColumn}
              sortColumnName={sortColumn}
              page={page}
              rowsPerPage={rowsPerPage}
              order={sortType}
            />
          ) : (
            <PosNoDataFound />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(GiftCardView);
